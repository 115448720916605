import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { Button, Input, Label } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import Select from 'react-select';
import memoize from 'memoize-one';
import moment from 'moment';
import { projectsActions, projectUsersActions, teamsActions } from '../redux/actions';
import '../assets/css/loader.css';
import DashboardHead from '../components/DashboardHead';
import AdminHead from '../components/AdminHead';
import { isBrowserView } from '../components/BrowserView';
import MobileView from '../components/MobileView';
import MixedView from '../components/MixedView';
import NewTooltip from '../components/NewTooltip';
import { CardLoader, InputLoader } from '../components/Loader';
import Page from '../components/Page';
import Pagination from '../components/Pagination';
import ProjectListItem from '../components/ProjectListItem';
import { nsOptions } from '../i18n';
import api from '../api';
import ErrorUtil from '../utils/ErrorUtil';
import downloadEndpoint from '../utils/downloadEndpoint';
import ComponentLifeTracker from '../utils/ComponentLifeTracker';
import ProjectCreateModal from '../components/ProjectCreateModal';
import ProjectImportManager from '../components/ProjectImportManager';
import DatePicker from '../components/DatePicker';
import CsvImportManager from '../components/CsvImportManager';
import {
  Table, TableBody, TableHead, TableRow, TableCell,
} from '../components/CustomTable';
import { API_DATE_TIME_FORMAT } from '../utils/date';
import { LabelledRadioGroup, RadioButton } from '../components/LabeledRadioGroup';

const BUTTON_PROGRESS_SIZE = 60;

const PROJECT_ACTIVATED = 'activated';
const PROJECT_DESACTIVATED = 'desactivated';
const PROJECT_ALL = 'all';


const ProjectFilter = (props) => {
  const {
    items, onChange, placeholder, labelKey, className, noOptionsMessage,
  } = props;

  const itemOptions = items.map((item) => ({ label: item[labelKey], value: item.id }));

  return (
    <Select
      className={`react-select ${className}`}
      classNamePrefix="react-select"
      onChange={(e) => {
        let newValue;
        if (e) {
          newValue = Number(e.value);
        }
        onChange(newValue);
      }}
      options={itemOptions}
      placeholder={placeholder}
      isClearable
      noOptionsMessage={noOptionsMessage}
    />
  );
};

ProjectFilter.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelKey: PropTypes.string,
  onChange: PropTypes.func,
  noOptionsMessage: PropTypes.func,
};

ProjectFilter.defaultProps = {
  className: '',
  labelKey: 'label',
  onChange: () => {},
  noOptionsMessage: () => {},
};

const mapStateToProps = (state) => ({
  user: state.auth.authUser,
  projects: state.projects,
  teams: state.teams,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchProjects: async (params, compLifeTracker) => dispatch(projectsActions.list(
    params, { pagination: 'short' }, () => compLifeTracker.isUnmounted(),
  )),
  fetchProjectUsers: async (projectIds, compLifeTracker) => dispatch(projectUsersActions.list({
    project__in: projectIds,
    admin: ownProps.admin,
  }, { pagination: 'no' }, () => compLifeTracker.isUnmounted())),
  fetchTeams: async (params) => dispatch(teamsActions.list(
    params,
    { pagination: 'no' },
  )),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class ProjectList extends Component {
  static propTypes = {
    i18n: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    admin: PropTypes.bool,
    projects: PropTypes.shape().isRequired,
    teams: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
    fetchProjectUsers: PropTypes.func.isRequired,
    fetchProjects: PropTypes.func.isRequired,
    fetchTeams: PropTypes.func.isRequired,
  };

  static defaultProps = {
    admin: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      projectsCount: 0,
      pageSize: 1,
      page: 1,
      projectLoading: false,
      searchLoading: false,
      managerId: null,
      ownerId: null,
      teamId: null,
      search: '',
      managers: [],
      owners: [],
      filterStartDate: null,
      filterEndDate: null,
      csvExportLoading: false,
      disabledStatus: PROJECT_ACTIVATED,
    };
    this.topics = [];
    this.getProjectsDOM = memoize((projects) => (
      this.loadProjects(Object.values(projects).sort((p1, p2) => (
        moment(p1.updated_at).isBefore(p2.updated_at) ? 1 : -1
      )))
    ));
    this.compLifeTracker = new ComponentLifeTracker();
  }

  async componentDidMount() {
    this.compLifeTracker.setMounted();
    await this.fetchData();
  }

  componentWillUnmount() {
    this.compLifeTracker.setUnmounted();
  }

  fetchData = async () => {
    const { admin, user, fetchTeams } = this.props;

    const canOwnLicenceProducts = user && user.limitations.can_own_licence_products;

    this.setState({ projectLoading: true });
    try {
      const promises = [this.fetchPaginatedData(1)];
      if (admin) promises.push(this.fetchManagers(), this.fetchOwners());
      if (admin || canOwnLicenceProducts) {
        const params = { admin, team_projects__isnull: false };
        if (canOwnLicenceProducts) params.owner = user.id;
        promises.push(fetchTeams(params));
      }

      await Promise.all(promises);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      this.setState({ projectLoading: false });
    }
  }

  fetchPaginatedData = async (page, manageLoadingFlag = true) => {
    if (manageLoadingFlag) this.setState({ projectLoading: true });
    const { fetchProjectUsers, admin } = this.props;
    try {
      const projects = await this.fetchProjects(page, this.compLifeTracker);
      if (admin) {
        await fetchProjectUsers(projects.map((project) => project.id), this.compLifeTracker);
      } else {
        await fetchProjectUsers(projects.map((project) => project.id), this.compLifeTracker);
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      if (this.compLifeTracker.isMounted()) {
        if (manageLoadingFlag) this.setState({ projectLoading: false });
        this.setState({ searchLoading: false });
      }
    }
  };

  getFilters = () => {
    const {
      managerId, ownerId, teamId, disabledStatus,
    } = this.state;
    const filters = {};

    if (managerId) filters.manager = managerId;
    if (ownerId) filters.owner = ownerId;
    if (teamId) filters.team = teamId;
    if (disabledStatus) {
      switch (disabledStatus) {
        case PROJECT_ACTIVATED:
          filters.is_disabled = false;
          break;
        case PROJECT_DESACTIVATED:
          filters.is_disabled = true;
          break;
        default:
          break;
      }
    }

    return filters;
  }

  fetchProjects = async (page) => {
    this.setState({ projectLoading: true });
    try {
      const {
        admin, fetchProjects,
      } = this.props;
      const {
        search, filterStartDate, filterEndDate,
      } = this.state;
      const filters = this.getFilters();

      const res = await fetchProjects({
        ordering: '-updated_at',
        page,
        search,
        admin,
        updated_at__date__gte: filterStartDate,
        updated_at__date__lte: filterEndDate,
        ...filters,
      }, this.compLifeTracker);
      if (this.compLifeTracker.isMounted()) {
        this.setState({
          page,
          projectsCount: res.count,
          pageSize: Math.max(res.results.length, this.state.pageSize),
        });
      }
      return res.results;
    } catch (error) {
      if (!ErrorUtil.handlePageNotFound(error, page, async () => this.fetchProjects(1))) {
        ErrorUtil.handleCatched(this.props, error, false);
      }
      return [];
    }
  };

  fetchManagers = async () => {
    const { admin } = this.props;
    try {
      const res = await api.list('users', { admin, manager: true }, { pagination: 'no' });
      this.setState({ managers: res });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  }

  fetchOwners = async () => {
    const { admin } = this.props;
    try {
      const res = await api.list('users', { admin, project_owner: true }, { pagination: 'no' });
      this.setState({ owners: res });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  }

  handleSearch = (key = '', value = '') => {
    this.setState({ [key]: value });
    clearTimeout(this.searchDelay);
    this.searchDelay = setTimeout(() => {
      this.setState({ searchLoading: true });
      this.fetchPaginatedData(1);
    }, 250);
  }

  handleSearchByProjectName = (value) => this.handleSearch('search', value);

  handleSearchManager = (value) => this.handleSearch('managerId', value);

  handleSearchOwner = (value) => this.handleSearch('ownerId', value);

  handleSearchTeam = (value) => this.handleSearch('teamId', value);

  handleSortActiveProjectFilter = (value) => this.handleSearch('disabledStatus', value);

  filterByLastActivity = (startDate = undefined, endDate = undefined) => {
    const { t } = this.props;
    // Timeout
    // Mutex
    const value = startDate || endDate;
    const date = !value ? value : moment(value);
    try {
      if (date && !date.isValid) {
        throw new Error(t('error:error.invalid-date'));
      } else {
        const newDate = date ? date.format(API_DATE_TIME_FORMAT) : null;
        this.setState((startDate !== undefined) ? { filterStartDate: newDate }
          : { filterEndDate: newDate });
        clearTimeout(this.searchDelay);
        this.searchDelay = setTimeout(() => {
          this.setState({ searchLoading: true });
          this.fetchPaginatedData(1);
        }, 250);
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  }

  ExportProjectList = async () => {
    this.setState({ csvExportLoading: true });
    const filters = this.getFilters();
    try {
      await downloadEndpoint(
        'projects/export-list',
        'get',
        {
          search: this.state.search,
          ...filters,
        },
      );
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      this.setState({ csvExportLoading: false });
    }
  };

  loadProjects(projects) {
    if (projects.length < 1) {
      return [(<ProjectListItem {...this.props} key="none" />)];
    }
    return projects.map((project) => (
      <ProjectListItem
        {...this.props}
        key={project.id}
        project={project}
      />
    ));
  }

  render() {
    const {
      t, admin, projects, user, teams: tms,
    } = this.props;
    const {
      projectLoading, projectsCount, pageSize, page, managers, filterStartDate, filterEndDate,
      owners, disabledStatus,
    } = this.state;
    const teams = Object.values(tms);
    const Head = admin ? AdminHead : DashboardHead;
    const projectsDOM = projectLoading ? [] : this.getProjectsDOM(projects);
    const projectsCountInfo = (
      <div className="text-bigger">
        { projectsCount && <span>{projectsCount}</span> }
        {' '}
        {t('common:dashboard.project', { count: projectsCount })}
      </div>
    );

    const canOwnLicenceProducts = user && user.limitations.can_own_licence_products;
    const showTeam = admin || canOwnLicenceProducts;
    const showOwner = admin || (user && (user.limitations.can_join_project_as_external
      || user.limitations.can_join_project_in_team));

    return (
      <Page
        {...this.props}
        title={t('common:nav.projects')}
      >
        <Head
          {...this.props}
          title={t('common:nav.projects')}
          subTitle={admin ? 'Find here Doqboard projects.' : null}
          breadcrumbPrevious={[]}
        />
        <MobileView>
          <div className="card bg-transparent border-0 pb-2 pl-1">
            <div className="device-view-unsupported">
              {t('project:connect-to-a-computer-to-create')}
            </div>
          </div>
        </MobileView>
        <div className="dashboard-content mb-2">
          <div className="card bg-white table-responsive contains-loader card-shadow p-4 mt-3 mobile-card disable-scroll-x">
            {
              this.state.projectLoading ? (
                <CardLoader />
              ) : null
            }
            <div className="row justify-content-start search-join-create-project-block">
              {
                !admin ? (
                  <div className="col-12">
                    <h4 className="font-weight-normal list-title">
                      {t('project:list-title', { context: canOwnLicenceProducts ? 'facility-accounts' : '' })}
                    </h4>
                  </div>
                ) : null
              }
              <div className="col-12">
                <div className="row justify-content-between mt-3">
                  <MixedView>
                    <div className={`${isBrowserView() ? 'col-12 col-md-auto' : 'col-6'} contains-loader`}>
                      <div className="row align-items-center">
                        <div className="col-auto pt-1">
                          {projectsCountInfo}
                        </div>
                        <div className="col-auto">
                          <FontAwesomeIcon
                            icon={['far', 'search']}
                            transform="grow-5"
                            className="mr-2 text-gray"
                          />
                          <Input
                            type="text"
                            id="projectse-input"
                            className="d-inline-block ml-1"
                            placeholder={t('project:search')}
                            style={{ width: '350px' }}
                            onKeyUp={(e) => this.handleSearchByProjectName(e.target.value)}
                          />
                        </div>
                        {showTeam && !admin && (
                          <div className="col-auto">
                            <ProjectFilter
                              id="team-select"
                              className="team-project-filter"
                              placeholder={t('user:team.filter-by-team')}
                              items={teams}
                              onChange={this.handleSearchTeam}
                              labelKey="name"
                              noOptionsMessage={() => t('user:team.no-teams')}
                            />
                          </div>
                        )}
                        {
                          this.state.searchLoading ? (
                            <div className="d-none d-md-block">
                              <InputLoader />
                            </div>
                          ) : null
                        }
                      </div>
                    </div>
                  </MixedView>
                  <MobileView>
                    <div className="col-12 col-md-auto contains-loader">
                      <div className="row justify-content-center pl-2">
                        <div className="col-10">
                          <div className="row justify-content-center">
                            <div className="col-auto pl-0">
                              { projectsCountInfo }
                            </div>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-auto d-flex align-items-center pr-0">
                              <FontAwesomeIcon
                                icon={['far', 'search']}
                                transform="grow-5"
                                className="mr-2 text-gray"
                              />
                            </div>
                            <div className="col-auto pl-0">
                              <Input
                                type="text"
                                id="projectse-input"
                                className="d-inline-block ml-1"
                                placeholder={t('project:search')}
                                style={{ maxWidth: '80%' }}
                                onKeyUp={(e) => this.handleSearchByProjectName(e.target.value)}
                              />
                            </div>
                          </div>
                          {
                            this.state.searchLoading && (
                              <div className="d-none d-md-block">
                                <InputLoader />
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </MobileView>
                  <MixedView>
                    <div className={isBrowserView() ? 'col-12 col-md-auto' : 'col-6 d-flex justify-content-end'}>
                      {
                        admin && (
                          <ProjectImportManager
                            admin={admin}
                          >
                            <Button
                              color="newyellow-2"
                              className="text-white mr-3"
                              style={{ boxShadow: 'none' }}
                            >
                              <FontAwesomeIcon
                                icon={['fal', 'plus']}
                                transform="grow-3"
                                className="mr-2"
                              />
                              <span className="ml-1">
                                {t('project:button.import')}
                              </span>
                            </Button>
                          </ProjectImportManager>
                        )
                      }
                      {
                        !admin && (
                          <Button
                            variant="contained"
                            className="btn btn-primary mr-3 contains-loader"
                            disabled={this.state.csvExportLoading}
                            onClick={this.ExportProjectList}
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'arrow-to-bottom']}
                              transform="grow-3"
                              className="mr-2"
                            />
                            <span className="ml-1">
                              {t('project:button.export-list')}
                            </span>
                            {this.state.csvExportLoading && (
                            <CircularProgress
                              size={BUTTON_PROGRESS_SIZE}
                              style={
                              {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-30px',
                                marginLeft: '-30px',
                              }
                            }
                            />
                            )}
                          </Button>
                        )
                      }
                      {admin && (
                        <CsvImportManager
                          admin={admin}
                        >
                          <button
                            className="btn btn-primary mr-3"
                          >
                            Import a CSV
                          </button>
                        </CsvImportManager>
                      )}
                      <ProjectCreateModal
                        admin={admin}
                        user={user}
                      >
                        <Button
                          color="newblue-1"
                          className="text-white"
                        >
                          <FontAwesomeIcon
                            icon={['fal', 'plus']}
                            transform="grow-3"
                            className="mr-2"
                          />
                          <span className="ml-1">
                            {t(`project:button.${isBrowserView() ? 'create' : 'create-short'}`)}
                          </span>
                        </Button>
                      </ProjectCreateModal>
                    </div>
                  </MixedView>
                </div>
              </div>
              <MixedView>
                <div className="col-12">
                  <div className="row mb-4 align-items-end projects-admin-filters">
                    {
                      admin && (
                        <>
                          <div
                            className="col-auto d-flex align-items-center"
                          >
                            <Label className="mr-2 mb-0">Manager :</Label>
                            <ProjectFilter
                              id="manager-select"
                              placeholder="Select a manager"
                              items={managers}
                              onChange={this.handleSearchManager}
                            />
                          </div>
                          <div
                            className="col-auto d-flex align-items-center"
                          >
                            <Label className="mr-2 mb-0">Owner :</Label>
                            <ProjectFilter
                              id="owner-select"
                              placeholder="Select an owner"
                              items={owners}
                              onChange={this.handleSearchOwner}
                            />
                          </div>
                        </>
                      )
                    }
                    {admin && showTeam && (
                      <div className="col-auto d-flex align-items-center">
                        <Label className="mr-2 mb-0">Team :</Label>
                        <ProjectFilter
                          id="admin-team-select"
                          placeholder={t('user:team.filter-by-team')}
                          items={teams}
                          onChange={this.handleSearchTeam}
                          labelKey="name"
                          noOptionsMessage={() => t('user:team.no-teams')}
                        />
                      </div>
                    )}
                  </div>
                  {
                    admin && (
                    <div className="row mt-1 mb-3 align-items-end admin-project-date-filters">
                      <div className="col-auto pr-0">
                        <Label
                          className="mb-1"
                        >
                          Last modification filter :
                        </Label>
                      </div>
                      <div className="col-auto">
                        <DatePicker
                          id="filter-start-date"
                          placeholder="Filtering start date"
                          onChange={(startDate) => this.filterByLastActivity(startDate)}
                          maxDate={filterEndDate ? moment(filterEndDate).add(-1, 'days') : null}
                        />
                      </div>
                      <div className="col-auto">
                        <DatePicker
                          id="filter-end-date"
                          placeholder="Filtering end date"
                          onChange={(endDate) => this.filterByLastActivity(undefined, endDate)}
                          minDate={filterStartDate ? moment(filterStartDate).add(1, 'days') : null}
                        />
                      </div>
                    </div>
                    )
                  }
                  <div className="col-auto d-flex align-items-center pl-0">
                    <NewTooltip
                      content={t('common:tooltip.filter')}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'filter']}
                        className="mr-2 text-gray-dark"
                      />
                    </NewTooltip>
                    <LabelledRadioGroup
                      className="mr-2 ml-2 mt-1 mb-0 inline-radio-group"
                      classNameRadioGroup="d-flex flex-row"
                      label={t('project:filter')}
                      name="activeProjectFilter"
                      onChange={(e) => {
                        const { value } = e.target;
                        this.handleSortActiveProjectFilter(value);
                      }}
                      value={disabledStatus}
                    >
                      <RadioButton value={PROJECT_ACTIVATED}>
                        {t('project:filters.status.active')}
                      </RadioButton>
                      <RadioButton value={PROJECT_DESACTIVATED}>
                        {t('project:filters.status.inactive')}
                      </RadioButton>
                      <RadioButton value={PROJECT_ALL}>
                        {t('project:filters.status.all')}
                      </RadioButton>
                    </LabelledRadioGroup>
                  </div>
                </div>
              </MixedView>
            </div>
            <MixedView>
              <Table extraClassName="mt-3 mb-0">
                <TableHead>
                  <TableRow>
                    <TableCell tag="th">
                      {t('project:project')}
                    </TableCell>
                    {showTeam && (
                      <TableCell tag="th">
                        {t('project:project-team')}
                      </TableCell>
                    )}
                    {showOwner && (
                      <TableCell tag="th">
                        {t('project:owner')}
                      </TableCell>
                    )}
                    <TableCell tag="th">
                      {t('project:manager')}
                    </TableCell>
                    <TableCell tag="th">
                      {t('project:status')}
                    </TableCell>
                    <TableCell tag="th">
                      {t('project:participants')}
                    </TableCell>
                    <TableCell tag="th">
                      {t(`project:inclusions.${admin ? 'all' : 'mine'}`)}
                    </TableCell>
                    {admin && (
                      <TableCell tag="th">
                        {t('project:last-change-date')}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    projectsDOM.length < 1 ? (
                      <TableRow
                        key="no-one"
                        className="no-one"
                      >
                        <TableCell
                          colSpan="8"
                        >
                          &nbsp;
                        </TableCell>
                      </TableRow>
                    ) : projectsDOM
                  }
                </TableBody>
              </Table>
            </MixedView>
            <MobileView>
              {
                projectsDOM.length < 1 ? (
                  <div key="no-one" className="no-one">
                    <div
                      colSpan="8"
                      className="align-middle text-center"
                    >
                      &nbsp;
                    </div>
                  </div>
                ) : projectsDOM
              }
            </MobileView>
            <nav className="mt-5 mb-0">
              <Pagination
                page={page}
                count={projectsCount}
                pageSize={pageSize}
                action={this.fetchPaginatedData}
              />
            </nav>
          </div>
        </div>
      </Page>
    );
  }
}


export default ProjectList;
