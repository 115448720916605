import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import FilterListIcon from '@material-ui/icons/FilterList';
import CancelIcon from '@material-ui/icons/Cancel';
import { fade } from '@material-ui/core/styles';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Switch,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import Chart, { CHART_HEIGHT } from './Chart';
import QuantitativeFilterModal from './QuantitativeFilterModal';
import TextStats from './TextStats';
import LicenseChecker from '../LicenseChecker';
import { nsOptions } from '../../i18n';
import { isQuantitative } from '../../utils/stats';
import ErrorUtil from '../../utils/ErrorUtil';
import Toast from '../../utils/Toast';
import statsFiltersActions from '../../redux/actions/stats-filters';
import { MEASUREMENTS, TIME } from '../../constants';
import ElementUtil from '../../utils/ElementUtil';
import FormattedValue from '../../utils/FormattedValue';
import Formatter from '../../utils/Formatter';
import DataOverviewModal from './DataOverviewModal';
import { CardLoader } from '../Loader';
import fromReduxState from '../../utils/redux';
import api from '../../api';

const styles = (theme) => ({
  empty: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    // Makes loader work
    position: 'relative',
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  chartAndTextContainer: {
    // Makes loader work
    position: 'relative',
  },
  chartContainer: {
    backgroundColor: fade(theme.palette.primary.main, 0.1),
  },
  chartSubContainer: {
    height: '100%',
  },
  textContainer: {
    backgroundColor: fade(theme.palette.primary.main, 0.08),
    overflowY: 'auto',
  },
  textSubContainer: {
    height: CHART_HEIGHT + theme.spacing(4),
    padding: `0 ${theme.spacing(4)}px`,
  },
  textContent: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  buttonContainer: {
    position: 'relative',
  },
  clickDescription: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[600],
  },
  container: {
    width: 'fit-content',
    marginTop: '0.3rem',
  },
  fab: {
    '&:focus': {
      outline: 'none',
    },
  },
});

class FilterButton extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    onValueFilter: PropTypes.func,
    onIntervalFilter: PropTypes.func,
    isFiltered: PropTypes.bool.isRequired,
    classes: PropTypes.shape().isRequired,
    project: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    onValueFilter: () => {},
    onIntervalFilter: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      filterTypeSelectEl: null,
    };
  }

  onOpenFilterTypeSelect = (event) => {
    this.setState({ filterTypeSelectEl: event.currentTarget });
  };

  onCloseFilterTypeSelect = () => {
    this.setState({ filterTypeSelectEl: null });
  };

  render() {
    const {
      t, type, onValueFilter, onIntervalFilter, isFiltered, classes, project,
    } = this.props;
    const { filterTypeSelectEl } = this.state;

    const hasMenu = isQuantitative(type) && !isFiltered;
    return (
      <>
        <LicenseChecker
          limName="can_use_stats_filters"
          limitations={project.limitations}
          key="filter-button"
        >
          <Fab
            variant="extended"
            onClick={hasMenu ? this.onOpenFilterTypeSelect : onValueFilter}
            aria-controls={hasMenu ? 'filter-type-select' : null}
            aria-haspopup={hasMenu ? true : null}
            className={classes.fab}
          >
            <FilterListIcon className={classes.filterIcon} />
            <Box ml={1}>
              {t(isFiltered ? 'stats:remove-filter' : 'stats:add-as-filter')}
            </Box>
          </Fab>
        </LicenseChecker>
        {
            hasMenu
              ? (
                <Menu
                  id="filter-type-select"
                  anchorEl={filterTypeSelectEl}
                  keepMounted
                  open={Boolean(filterTypeSelectEl)}
                  onClose={this.onCloseFilterTypeSelect}
                >
                  <MenuItem key="filter-value" onClick={onValueFilter}>
                    { t('stats:filter-by-value') }
                  </MenuItem>
                  <MenuItem key="filter-interval" onClick={onIntervalFilter}>
                    { t('stats:filter-by-interval') }
                  </MenuItem>
                </Menu>
              )
              : null
          }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  statsFilters: state.statsFilters,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleFilter: async (filter) => dispatch(statsFiltersActions.toggleFilter(filter)),
  removeFilters: () => dispatch(statsFiltersActions.deleteTargetFilters(ownProps.id)),
});

@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
@withStyles(styles)
class VariableCard extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    elementType: PropTypes.string,
    unit: PropTypes.string,
    seriesNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    count: PropTypes.number,
    seriesNamesCounts: PropTypes.shape().isRequired,
    iterations: PropTypes.arrayOf(PropTypes.shape()),
    chartData: PropTypes.arrayOf(PropTypes.any).isRequired,
    textStats: PropTypes.shape().isRequired,
    inModule: PropTypes.bool,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape().isRequired,
    statsFilters: PropTypes.shape().isRequired,
    toggleFilter: PropTypes.func.isRequired,
    removeFilters: PropTypes.func.isRequired,
    loadData: PropTypes.func.isRequired,
    statsLoading: PropTypes.bool,
    project: PropTypes.shape().isRequired,
    canUseAdvancedTools: PropTypes.bool.isRequired,
    filtersParams: PropTypes.shape(),
    isLinkTarget: PropTypes.bool,
    teamId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    userId: PropTypes.number,
    creatorEmail: PropTypes.string,
    decompositionMode: PropTypes.string,
  };

  static defaultProps = {
    type: null,
    elementType: null,
    unit: '',
    count: null,
    inModule: false,
    iterations: null,
    statsLoading: false,
    filtersParams: null,
    isLinkTarget: false,
    teamId: null,
    userId: null,
    creatorEmail: null,
    decompositionMode: 'none',
  };

  state = {
    activeIndex: null,
    activeSeriesName: null,
    activeClick: false,
    currentPage: 1,
    pageSize: 10,
    ElementArrayData: null,
    loading: false,
    showQuantitativeFilterModal: false,
    togglingFilter: false,
    viewMode: 'graph',
    fetchOption1: '',
    fetchOption2: '',
    chartData: this.props.chartData,
    seriesNames: this.props.seriesNames,
  };

  activeTimeout = -1;

  componentDidMount() {
    if (this.state.viewMode === 'table') {
      this.loadProjectElementToTable();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.viewMode === 'table' && prevState.viewMode !== 'table') {
      this.loadProjectElementToTable();
    }
    if (prevProps.type !== this.props.type && this.state.viewMode === 'table') {
      this.loadProjectElementToTable();
    }
  }

  // eslint-disable-next-line react/sort-comp
  fetchChartData = async () => {
    // eslint-disable-next-line react/prop-types
    const { project, id, toastManager } = this.props;
    const { fetchOption1, fetchOption2 } = this.state;

    if (!fetchOption1 || !fetchOption2) {
      return;
    }

    this.setState({ loading: true });
    try {
      const response = await api.requestData(
        `projects/${project.id}/single-element-stats`,
        undefined,
        'post',
        this.queryParams,
        {
          projectElementId: id,
          xAxis: fetchOption1,
          yAxis: fetchOption2,
        },
      );
      if (response.chartType === 'bar') {
        const { xAxis, series } = response;
        const data = xAxis.map((label, i) => {
          const obj = { name: label };
          series.forEach((s) => {
            obj[s.name] = s.data[i];
          });
          return obj;
        });
        const seriesNames = series.map((s) => s.name);
        this.setState({
          chartData: data,
          seriesNames,
          loading: false,
        });
      } else {
        console.log('response other', response);
        this.setState({
          chartData: response.data,
          seriesNames: response.seriesNames,
          loading: false,
        });
      }
    } catch (error) {
      if (toastManager) {
        // eslint-disable-next-line react/prop-types
        toastManager.add(`${error.message}`, { appearance: 'error' });
      }
      this.setState({ loading: false });
    }
  };

  handleSelect1Change = (event) => {
    const newOption1 = event.target.value;
    let newOption2 = this.state.fetchOption2;
    const allOptions = ['n° saisie', 'valeur', 'centre/user'];
    if (newOption1 === 'centre/user') {
      newOption2 = 'valeur';
    } else if (newOption1 === newOption2) {
      newOption2 = allOptions.find((o) => o !== newOption1 && o !== '');
    }
    this.setState({ fetchOption1: newOption1, fetchOption2: newOption2 }, () => {
      if (this.state.fetchOption1 && this.state.fetchOption2) {
        if (this.state.viewMode === 'graph') {
          this.fetchChartData();
        } else {
          this.fetchTableData();
        }
      }
    });
  };

  handleSelect2Change = (event) => {
    const newOption2 = event.target.value;
    this.setState({ fetchOption2: newOption2 }, () => {
      if (this.state.fetchOption1 && this.state.fetchOption2) {
        if (this.state.viewMode === 'graph') {
          this.fetchChartData();
        } else {
          this.fetchTableData();
        }
      }
    });
  };

  // eslint-disable-next-line react/sort-comp
  handleToggleView = () => {
    this.setState((prevState) => ({
      viewMode: prevState.viewMode === 'graph' ? 'table' : 'graph',
      fetchOption1: '',
      fetchOption2: '',
    }));
  };

  loadProjectElementToTable = async () => {
    const {
      // eslint-disable-next-line react/prop-types
      toastManager,
      project,
      id,
      teamId,
      userId,
      creatorEmail,
      decompositionMode,
    } = this.props;

    const {
      currentPage,
      pageSize,
    } = this.state;

    this.setState({ loading: true });

    try {
      const data = await api.requestData(
        `projects/${project.id}/single-element-table-stats`,
        undefined,
        'post',
        this.queryParams,
        {
          projectElementId: id,
          page: currentPage,
          page_size: pageSize,
          user_id: userId || null,
          team_id: teamId || null,
          creator_email: creatorEmail || '',
          decomposition_mode: decompositionMode || 'none',
        },
      );

      this.setState({
        ElementArrayData: data,
        loading: false,
      });
    } catch (error) {
      console.error(error);
      if (toastManager) {
        // eslint-disable-next-line react/prop-types
        toastManager.add(this.props.t('stats:table-loading-error', { error: error.message }), {
          appearance: 'error',
        });
      }
      this.setState({ loading: false });
    }
  };

  fetchTableData = async () => {
    const {
      // eslint-disable-next-line react/prop-types
      project, id, teamId, userId, creatorEmail, decompositionMode, toastManager,
    } = this.props;
    const {
      currentPage, pageSize, fetchOption1, fetchOption2,
    } = this.state;
    this.setState({ loading: true });
    try {
      const data = await api.requestData(
        `projects/${project.id}/single-element-table-stats-with-axis`,
        undefined,
        'post',
        this.queryParams,
        {
          projectElementId: id,
          page: currentPage,
          page_size: pageSize,
          user_id: userId || null,
          team_id: teamId || null,
          creator_email: creatorEmail || '',
          decomposition_mode: decompositionMode || 'none',
          xAxis: fetchOption1,
          yAxis: fetchOption2,
        },
      );
      console.log(data);
      this.setState({ ElementArrayData: data, loading: false });
    } catch (error) {
      if (toastManager) {
        // eslint-disable-next-line react/prop-types
        toastManager.add(this.props.t('stats:table-loading-error', { error: error.message }), {
          appearance: 'error',
        });
      }
      this.setState({ loading: false });
    }
  };

  handlePageChange = (event, newPage) => {
    this.setState(
      { currentPage: newPage + 1 },
      () => this.loadProjectElementToTable(),
    );
  };

  handleRowsPerPageChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    this.setState(
      { pageSize: newPageSize, currentPage: 1 },
      () => this.loadProjectElementToTable(),
    );
  };


  getVariableFilters = fromReduxState((statsFilters, id) => (
    Object.values(statsFilters).filter((filter) => (
      filter.targetId === id
    ))
  ));

  get stats() {
    try {
      const {
        type, unit, count, seriesNames, iterations, chartData, inModule, t,
        classes, project, canUseAdvancedTools, filtersParams, id, isLinkTarget,
        elementType,
      } = this.props;
      const {
        activeIndex, activeSeriesName, activeClick, showQuantitativeFilterModal,
        togglingFilter,
      } = this.state;
      if (activeIndex === null || activeIndex < 0 || activeSeriesName === null || togglingFilter) {
        return null;
      }

      let name;
      let payload;

      if (inModule) {
        name = seriesNames[activeIndex];
        payload = chartData.length === 1
          ? chartData[0] : chartData.find((row) => row.name === activeSeriesName);
      } else {
        name = 'value';
        payload = chartData[activeIndex];
      }

      // Deep copy of filtersParams
      const overviewFilters = JSON.parse(JSON.stringify(filtersParams));
      if (activeClick && !Object.keys(overviewFilters.filters).includes(id)) {
        const fakeFilter = this.getPendingFilter();
        overviewFilters.filters[fakeFilter.targetId] = { value: fakeFilter.value };
        if (inModule) {
          overviewFilters.filters[fakeFilter.targetId].series_name = name;
        }
      }

      if (!payload) return null; // May happen in case of outdated activeIndex vs new chartData

      const value = payload[name];
      const probability = value / count;
      const payloadName = payload.name || t('common:elements.default-labels.answer');

      const title = `${inModule ? `${name} / ` : ''}${payloadName}${unit ? `  ${unit}` : ''}`;

      return (
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <strong>
                  {inModule ? `${name} - ` : ''}
                  {payloadName}
                  {'  '}
                  {unit}
                </strong>
              </Grid>
              <Grid item>
                <FormattedValue value={probability} isRatio />
                {` (${value}/${count} ${t('stats:inclusions.unit')})`}
              </Grid>
              <Grid item>
                { t('stats:ci95') }
                  &nbsp;
                <FormattedValue
                  value={payload.confidence_interval_95[name]}
                  isRatio
                  closedLower
                  closedUpper
                  emptyComponent={<em>{t('stats:not-applicable')}</em>}
                />
              </Grid>
              {inModule && type === MEASUREMENTS ? (
                <Grid item>
                  { t('stats:mean-entry') }
                  :&nbsp;
                  <FormattedValue
                    value={
                            iterations.find(
                              (iteration) => iteration.name === seriesNames[activeIndex],
                            ).count
                          }
                  />
                      &nbsp;
                  {unit}
                </Grid>
              ) : null}
              {activeClick && (
                <Grid item>
                  <DataOverviewModal
                    id={id}
                    name={this.props.name}
                    count={value}
                    filtersParams={overviewFilters}
                    project={project}
                    isLinkTarget={isLinkTarget}
                    modalitySelected
                    modalityTitle={title}
                    elementType={elementType}
                    showLoader={this.showLoader}
                    canUseAdvancedTools={canUseAdvancedTools}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item className={classes.buttonContainer}>
            <div className={classes.clickDescription} hidden={activeClick}>
              {t('stats:click-description')}
            </div>
            <div align="center" hidden={!canUseAdvancedTools || !activeClick}>
              <FilterButton
                type={type}
                t={t}
                onValueFilter={this.valueFilter}
                onIntervalFilter={this.intervalFilter}
                isFiltered={this.isFiltered(activeIndex, activeSeriesName)}
                classes={classes}
                project={project}
              />
              <QuantitativeFilterModal
                open={showQuantitativeFilterModal}
                onClose={() => { this.setState({ showQuantitativeFilterModal: false }); }}
                onValidate={this.validateQuantitativeFilter}
                type={type}
              />
            </div>
          </Grid>
        </Grid>
      );
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
      return null;
    }
  }

  get text() {
    const { activeSeriesName } = this.state;
    if (activeSeriesName !== null) {
      const { stats } = this;
      if (stats) {
        return stats;
      }
    }

    const {
      id, type, unit, count, textStats, canUseAdvancedTools, seriesNamesCounts, inModule, name,
      filtersParams, isLinkTarget, project, elementType,
    } = this.props;
    return (
      <TextStats
        id={id}
        type={type}
        unit={unit}
        count={count}
        seriesNamesCounts={seriesNamesCounts}
        inModule={inModule}
        variableName={name}
        filtersParams={filtersParams}
        isLinkTarget={isLinkTarget}
        project={project}
        elementType={elementType}
        showLoader={this.showLoader}
        canUseAdvancedTools={canUseAdvancedTools}
        {...textStats}
      />
    );
  }

  isFiltered = (index, seriesName) => {
    const {
      inModule, chartData, statsFilters, id,
    } = this.props;
    const variableFilters = this.getVariableFilters(statsFilters, id);
    if (inModule) {
      return variableFilters.some((filter) => filter.formattedValue === seriesName);
    }
    const row = chartData[index];
    return row && variableFilters.some(
      (filter) => filter.value === (row.rawName || row.name),
    );
  };

  setActive = (index, seriesName, click) => {
    clearTimeout(this.activeTimeout);
    const { activeIndex, activeSeriesName, activeClick } = this.state;
    const isSame = (
      (index === activeIndex) && (seriesName === activeSeriesName)
    );
    if (click && activeClick && isSame) {
      this.unsetActive(true);
    } else if ((click && activeClick && !isSame) || !activeClick) {
      this.setState({
        activeIndex: index,
        activeSeriesName: seriesName,
        activeClick: click,
      });
    }
  };

  unsetActive = (synchronous) => {
    const changeState = () => {
      this.setState({
        activeIndex: null,
        activeSeriesName: null,
        activeClick: false,
      });
      this.activeTimeout = -1;
    };
    if (synchronous && this.state.activeClick) {
      changeState();
    } else if (!synchronous && !this.state.activeClick) {
      clearTimeout(this.activeTimeout);
      this.activeTimeout = setTimeout(changeState, 250);
    }
  };

  getPendingFilter = () => {
    const { activeIndex, activeSeriesName } = this.state;
    const {
      chartData, id, name, inModule, type,
    } = this.props;

    if (activeIndex === undefined || activeSeriesName === null) {
      return null;
    }

    try {
      let activeRow;
      let index;
      if (inModule) {
        index = chartData.length === 1
          ? 0 : chartData.findIndex((row) => row.name === activeSeriesName);
        activeRow = chartData[index];
      } else {
        index = activeIndex;
        activeRow = chartData[activeIndex];
      }
      const value = activeRow.rawName;
      const isLastIndex = index === chartData.length - 1;
      const additionalOptions = {};
      if (isQuantitative(type)) {
        additionalOptions.lowerBound = true;
        additionalOptions.upperBound = isLastIndex;
      }
      return {
        targetId: id,
        targetName: name,
        value,
        formattedValue: activeRow.name,
        ...additionalOptions,
      };
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
      return null;
    }
  };

  toggleFilter = async (filter) => {
    const { toggleFilter, loadData } = this.props;
    await toggleFilter(filter);
    try {
      await loadData();
      this.unsetActive(true);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  };

  removeFilter = () => {
    const { removeFilters, loadData } = this.props;
    removeFilters();
    loadData();
  };

  valueFilter = async () => {
    const { activeIndex, activeSeriesName } = this.state;

    if (activeIndex !== undefined && activeSeriesName !== null) {
      const filter = this.getPendingFilter();
      if (filter) {
        this.setState({ togglingFilter: true });
        await this.toggleFilter(filter);
        this.setState({ togglingFilter: false });
      } else {
        Toast.error(this.props, 'Not supported yet');
      }
    }
  };

  intervalFilter = () => {
    this.setState({ showQuantitativeFilterModal: true });
  };

  validateQuantitativeFilter = async (lowBound, highBound, includeLimits) => {
    this.setState({ showQuantitativeFilterModal: false });

    const { id, name, type } = this.props;
    const rawValue = [lowBound, highBound];
    const formatter = new Formatter(type, null, null, false, includeLimits, includeLimits);
    const value = rawValue.map((bound) => {
      if (bound instanceof moment) {
        return type === TIME ? formatter.formatDateTime(bound) : bound.format();
      }
      return bound;
    });
    const filter = {
      targetId: id,
      targetName: name,
      value,
      formattedValue: formatter.format(rawValue),
      lowerBound: includeLimits,
      upperBound: includeLimits,
    };

    this.setState({ togglingFilter: true });
    await this.toggleFilter(filter);
    this.setState({ togglingFilter: false });
  };

  showLoader = (show = true) => this.setState({ loading: show });

  renderCenterVariableTable = () => {
    const { t, classes, inModule } = this.props;
    const {
      ElementArrayData, loading, fetchOption1, fetchOption2,
    } = this.state;

    if (loading) {
      return (
        <div className={classes.empty}>
          <CardLoader />
        </div>
      );
    }

    if (!ElementArrayData) {
      return <div>{t('stats:no-table-data')}</div>;
    }

    const { columns, rows } = ElementArrayData;
    if (!Array.isArray(columns) || !Array.isArray(rows)) {
      return <div>{t('stats:no-table-data')}</div>;
    }

    const options = [
      { key: 'n° saisie', label: t('stats:inputNumber') },
      { key: 'valeur', label: t('stats:value') },
      { key: 'centre/user', label: t('stats:centerUser') },
    ];

    const headerMapping = {
      Centre: t('stats:center'),
      Utilisateur: t('stats:user'),
    };

    return (
      <div>
        {inModule && (
          <div style={{ marginBottom: 16 }}>
            <select
              id="select1"
              value={fetchOption1}
              onChange={this.handleSelect1Change}
              style={{ marginRight: 16 }}
            >
              <option value="">{t('stats:columns')}</option>
              {options.map((option) => (
                <option key={option.key} value={option.key}>
                  {option.label}
                </option>
              ))}
            </select>
            <select
              id="select2"
              value={fetchOption2}
              onChange={this.handleSelect2Change}
            >
              <option value="">{t('stats:lines')}</option>
              {fetchOption1 === 'centerUser'
                ? <option value="valeur">{t('stats:value')}</option>
                : options
                  .filter((o, index) => index < 2 && o.key !== fetchOption1)
                  .map((option) => (
                    <option key={option.key} value={option.key}>
                      {option.label}
                    </option>
                  ))}
            </select>
          </div>
        )}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                {columns.map((col, index) => (
                  <TableCell key={col} style={{ fontWeight: 'bold' }}>
                    {index === 0 && headerMapping[col] ? headerMapping[col] : col}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow>
                  {row.map((cell, cellIndex) => (
                    <TableCell
                      style={{
                        // eslint-disable-next-line no-nested-ternary
                        fontWeight: cellIndex === 0 ? 'bold'
                          : cell === 'Total' ? 'bold' : 'normal',
                      }}
                    >
                      {cell}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 50]}
          count={ElementArrayData.total_count}
          rowsPerPage={this.state.pageSize}
          page={this.state.currentPage - 1}
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
          SelectProps={{ style: { minWidth: '80px' } }}
        />
      </div>
    );
  };


  render() {
    const {
      id, unit, count, t, classes, statsFilters,
      statsLoading, name, filtersParams, project, isLinkTarget, elementType, canUseAdvancedTools,
      decompositionMode,
    } = this.props;
    const {
      loading, viewMode, chartData, seriesNames,
    } = this.state;
    const variableFilters = this.getVariableFilters(statsFilters, id);
    let content;

    const options = [
      { key: 'n° saisie', label: t('stats:inputNumber') },
      { key: 'valeur', label: t('stats:value') },
      { key: 'centre/user', label: t('stats:centerUser') },
    ];

    if ((chartData.length === 0) || (count === 0)) {
      content = (
        <Container className={classes.empty}>
          {loading && <CardLoader />}
          <Typography align="center">
            {t('stats:empty')}
          </Typography>
          <Container className={classes.container}>
            <DataOverviewModal
              id={id}
              name={name}
              count={count}
              filtersParams={filtersParams}
              project={project}
              isLinkTarget={isLinkTarget}
              elementType={elementType}
              showLoader={this.showLoader}
              canUseAdvancedTools={canUseAdvancedTools}
            />
          </Container>
        </Container>
      );
    } else if (viewMode === 'graph') {
      content = (
        <Grid
          container
          alignItems="stretch"
          spacing={4}
          className={classes.chartAndTextContainer}
        >
          {loading && <CardLoader />}

          <Grid item xs={12} md={8} className={classes.chartContainer}>
            {this.props.inModule && (
            <div style={{ marginBottom: 16 }}>
              <select
                id="select1"
                value={this.state.fetchOption1}
                onChange={this.handleSelect1Change}
                style={{ marginRight: 16 }}
              >
                <option value="">{t('stats:abscisse')}</option>
                {options.map((option) => (
                  <option key={option.key} value={option.key}>
                    {option.label}
                  </option>
                ))}
              </select>
              <select
                id="select2"
                value={this.state.fetchOption2}
                onChange={this.handleSelect2Change}
              >
                <option value="">{t('stats:ordonnee')}</option>
                {this.state.fetchOption1 === 'centerUser'
                  ? <option value="valeur">{t('stats:value')}</option>
                  : options.filter((o, index) => index < 2 && o.key
                    !== this.state.fetchOption1).map((option) => (
                      <option key={option.key} value={option.key}>
                        {option.label}
                      </option>
                  ))}
              </select>
            </div>
            )}
            <Chart
              id={this.props.id}
              type={this.props.type}
              unit={this.props.unit}
              seriesNames={seriesNames}
              count={this.props.count}
              data={chartData}
              setActive={this.setActive}
              unsetActive={this.unsetActive}
              activeIndex={this.state.activeIndex}
              activeSeriesName={this.state.activeSeriesName}
              activeClick={this.state.activeClick}
            />
          </Grid>

          <Grid item xs={12} md={4} className={classes.textContainer}>
            <Grid container direction="row" spacing={4} className={classes.textSubContainer}>
              <Grid item xs={12} className={classes.textContent}>
                {this.text}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      content = this.renderCenterVariableTable();
    }

    const title = (
      <Grid container direction="column">
        <Grid item>
          {ElementUtil.formatElementName(this.props, t)}
        </Grid>
        {
            variableFilters.length
              ? (
                <Grid item>
                  {variableFilters.map((filter) => (
                    <Chip
                      key={filter.targetId}
                      icon={<FilterListIcon />}
                      label={(
                        <FormattedValue
                          value={filter.formattedValue
                                          || t('common:elements.default-labels.answer')}
                          unit={unit}
                        />
                              )}
                      onDelete={this.removeFilter}
                      deleteIcon={(
                        <Tooltip
                          title={t('stats:remove-filter')}
                          placement="top"
                        >
                          <CancelIcon />
                        </Tooltip>
                              )}
                      size="small"
                      color="primary"
                      disabled={statsLoading}
                    />
                  ))}
                </Grid>
              )
              : null
          }
      </Grid>
    );

    return (
      <Card raised={this.props.inModule}>
        <CardHeader
          title={title}
          titleTypographyProps={{ align: 'center', variant: 'h6' }}
          action={(
                (decompositionMode !== 'none') ? (
                  <Switch
                    checked={viewMode === 'table'}
                    onChange={this.handleToggleView}
                    color="primary"
                    inputProps={{ 'aria-label': 'switch view mode' }}
                  />
                ) : null
              )}
        />
        <CardContent className={classes.cardContent}>
          {content}
        </CardContent>
      </Card>
    );
  }
}

export default VariableCard;
