import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, DialogContentText, FormControl, FormControlLabel, makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import Modal from './Modal';
import { childrenPropTypes } from '../../utils/generic-prop-types';
import { NewBadge } from '../Badges';
import { LabelledRadioGroup, RadioButton } from '../LabeledRadioGroup';

const FLAT = 'flat';
const MULTI_TABLES = 'multiple-tables';

const checkBoxStyleBase = {
  '& .MuiIconButton-root': {
    paddingBottom: 0,
    paddingTop: 0,
  },
};

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  form: {
    '& span': {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  format: {
    marginTop: theme.spacing(1.5),
  },
  formatCheckBox: {
    ...checkBoxStyleBase,
  },
  formElement: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  headerType: {
    ...checkBoxStyleBase,
    marginLeft: theme.spacing(2),
  },
  group: {
    marginBottom: theme.spacing(3),
  },
  groupLabel: {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: '600',
    marginBottom: '1.0rem',
  },
  radioControl: {
  },
});

const useStyles = makeStyles(styles);

function LabelledCheckBox(props) {
  const {
    checked, children, className, disabled, onChange,
  } = props;
  const classes = useStyles();
  return (
    <div>
      <FormControlLabel
        className={className || classes.formElement}
        control={(
          <Checkbox
            checked={checked}
            onChange={onChange}
            color="primary"
          />
        )}
        disabled={disabled}
        label={children}
      />
    </div>
  );
}

LabelledCheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  children: childrenPropTypes().isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

LabelledCheckBox.defaultProps = {
  className: '',
  disabled: false,
};

const initialState = {
  anonymous: true,
  inconsistentData: false,
  format: FLAT,
  idAsheader: false,
  idAsHeaderEnabled: false,
};

function DataExportModal(props) {
  const { open } = props;
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [anonymous, setAnonymous] = useState(initialState.anonymous);
  const [inconsistentData, setInconsistentData] = useState(initialState.inconsistentData);
  const [format, setFormat] = useState(initialState.format);
  const [idAsHeader, setIdAsHeader] = useState(initialState.idAsheader);
  const [idAsHeaderEnabled, setIdAsHeaderEnabled] = useState(initialState.idAsHeaderEnabled);

  const resetState = () => {
    setAnonymous(initialState.anonymous);
    setInconsistentData(initialState.inconsistentData);
    setFormat(initialState.format);
    setIdAsHeader(initialState.idAsheader);
    setIdAsHeaderEnabled(initialState.idAsHeaderEnabled);
  };

  const onClose = () => {
    const { onClose: onQuit } = props;
    resetState();
    onQuit();
  };

  const onValidate = () => {
    const { onValidate: onExportData } = props;
    onExportData({
      flat: format === FLAT,
      anonymous,
      inconsistent_data: inconsistentData,
      id_as_header: idAsHeader,
    });
    onClose();
  };

  const onFormatChange = (e) => {
    const { value } = e.target;
    setFormat(value);
    setIdAsHeaderEnabled(value === MULTI_TABLES);
    if (value === FLAT) setIdAsHeader(false);
  };

  return (
    <Modal
      onClose={onClose}
      onClickClose={onClose}
      onClickValidate={onValidate}
      open={open}
      paperProps={{ className: classes.root }}
      title={t('stats:data-export.title')}
    >
      <DialogContentText>
        { t('stats:data-export.subtitle') }
      </DialogContentText>
      <FormControl
        className={classes.form}
        fullWidth
      >
        <div className={classes.group}>
          <div className={classes.groupLabel}>{t('stats:data-export.type')}</div>
          <LabelledCheckBox
            checked={inconsistentData}
            className={classes.formatCheckBox}
            onChange={(e) => { setInconsistentData(e.target.checked); }}
          >
            <span>
              {t('stats:data-export.export-inconsistent-data')}
            </span>
            <Tooltip title={t('stats:data-export.invalid-values-checkbox')} placement="right">
              <span>
                <FontAwesomeIcon
                  icon={['fal', 'question-circle']}
                  className="text-muted ml-2 mr-4"
                  transform="shrink-2"
                />
              </span>
            </Tooltip>
          </LabelledCheckBox>
          <LabelledCheckBox
            checked={!anonymous}
            className={classes.formatCheckBox}
            onChange={(e) => { setAnonymous(!e.target.checked); }}
          >
            {t('stats:data-export.export-indirect-identifying')}
          </LabelledCheckBox>
          <div className="help-text">
            {t('stats:data-export.help')}
          </div>
        </div>
        <div className={classes.group}>
          <LabelledRadioGroup
            className={classes.format}
            label={(
              <NewBadge className={`csv-format-badge-${i18n.language}`}>
                <span>
                  {t('stats:data-export.format')}
                </span>
              </NewBadge>
            )}
            name="Data export format"
            onChange={onFormatChange}
            value={format}
          >
            <RadioButton value={FLAT}>
              {t('stats:data-export.flat')}
            </RadioButton>
            <RadioButton value={MULTI_TABLES}>
              {t('stats:data-export.multi-tables')}
            </RadioButton>
          </LabelledRadioGroup>
          <LabelledCheckBox
            checked={idAsHeader}
            className={classes.headerType}
            disabled={!idAsHeaderEnabled}
            onChange={(e) => setIdAsHeader(e.target.checked)}
          >
            {t('stats:data-export.header-type')}
          </LabelledCheckBox>
        </div>
      </FormControl>
    </Modal>
  );
}

DataExportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DataExportModal;
