import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, makeStyles,
} from '@material-ui/core';
import { childrenPropTypes } from '../utils/generic-prop-types';

const checkBoxStyleBase = {
  '& .MuiIconButton-root': {
    paddingBottom: 0,
    paddingTop: 0,
  },
};

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  form: {
    '& span': {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  format: {
    marginTop: theme.spacing(1.5),
  },
  formatCheckBox: {
    ...checkBoxStyleBase,
  },
  formElement: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  headerType: {
    ...checkBoxStyleBase,
    marginLeft: theme.spacing(2),
  },
  group: {
    marginBottom: theme.spacing(3),
  },
  groupLabel: {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: '600',
    marginBottom: '1.0rem',
  },
  radioControl: {
  },
});

const useStyles = makeStyles(styles);

function LabelledRadioGroup(props) {
  const {
    children, className, label, name, onChange, value, classNameRadioGroup,
  } = props;
  const classes = useStyles();
  return (
    <FormControl className={className || classes.formElement}>
      <FormLabel className={classes.groupLabel} id="radio-buttons-group">
        {label}
      </FormLabel>
      <RadioGroup
        aria-labelledby="radio-buttons-group"
        name={name}
        value={value}
        onChange={onChange}
        className={classNameRadioGroup}
      >
        {children}
      </RadioGroup>
    </FormControl>
  );
}

LabelledRadioGroup.propTypes = {
  children: childrenPropTypes().isRequired,
  className: PropTypes.string,
  label: childrenPropTypes().isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  classNameRadioGroup: PropTypes.string,
};

LabelledRadioGroup.defaultProps = {
  className: '',
  classNameRadioGroup: '',
};

function RadioButton(props) {
  const { value, children, disabled } = props;
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classes.radioControl}
      control={<Radio />}
      disabled={disabled}
      label={children}
      value={value}
    />
  );
}

RadioButton.propTypes = {
  children: childrenPropTypes().isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

RadioButton.defaultProps = {
  disabled: false,
};

export { LabelledRadioGroup, RadioButton };
