import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import memoize from 'memoize-one';
import { connect } from 'react-redux';
import { projectEntriesActions } from '../redux/actions';
import { getSortingItemId, extractSortingItemDigitalId } from '../redux/actions/sorting-handler';
import { nsOptions } from '../i18n';
import SortUtil from '../utils/SortUtil';
import TextUtil from '../utils/TextUtil';
import TimeoutHandler from '../utils/TimeoutHandler';
import Toast from '../utils/Toast';
import ElementBase, { retrieveProjectEntries } from './ElementBase';
import ElementComment from './ElementComment';
import ElementCommentManager from './ElementCommentManager';
import ElementContentEditable from './ElementContentEditable';
import withChoicesManagement from './withChoicesManagement';
import IconConfirm from './IconConfirm';
import NewTooltip from './NewTooltip';
import DroppableComponent from './DroppableComponent';
import DraggableComponent from './DraggableComponent';
import CustomAsyncSelect, { defaultStyles } from './CustomAsyncSelect';
import Help from './Help';
import ErrorUtil from '../utils/ErrorUtil';
import ElementUtil from '../utils/ElementUtil';
import {
  ELEMENT_TYPE_UNIQUE_CHOICE, ELEMENT_TYPE_MULTIPLE_CHOICES, ENTRY_TYPE_MULTIPLE_CHOICES,
  ENTRY_TYPE_UNIQUE_CHOICE,
} from '../constants';

const MIN_MODALITIES_TO_SHOW_SWITCH = 4;

const mapStateToProps = (state, ownProps) => ({
  element: state.elements[ownProps.elementId],
  inclusion: state.inclusions[ownProps.inclusionId],
  projectElement: state.projectElements[ownProps.projectElementId],
  projectEntries: state.projectEntries,
  projectEntryMissing: ownProps.inclusion ? retrieveProjectEntries(
    Object.values(state.projectEntries),
    ownProps.inclusionId,
    ownProps.projectElementId,
    ownProps.moduleInstanceId,
  ).find((entry) => entry.missing) : null,
  sortedItems: state.sortingHandler[ownProps.elementSortingHandlerId] || [],
  modalOpened: state.dragAndDrop.modalsOpened > 0,
  elementModalities: state.elementModalities,
});

const mapDispatchToProps = (dispatch) => ({
  addEntry: async (data) => dispatch(projectEntriesActions.create(data)),
  removeEntry: async (id) => dispatch(projectEntriesActions.remove(id)),
  patchEntry: async (id, data) => dispatch(projectEntriesActions.patch(id, data)),
  resyncProjectEntries: async () => dispatch(projectEntriesActions.resync()),
});


@withChoicesManagement
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withToastManager
@withTranslation('', nsOptions)
class ElementChoice extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape().isRequired,
    elementId: PropTypes.number.isRequired,
    projectElementId: PropTypes.number,
    project: PropTypes.shape().isRequired,
    moduleInstanceId: PropTypes.number,
    inclusion: PropTypes.shape(),
    inclusionId: PropTypes.number,
    parent: PropTypes.shape({
      content: PropTypes.shape(),
    }),
    methods: PropTypes.shape({
      move: PropTypes.func,
      reload: PropTypes.func.isRequired,
      remove: PropTypes.func,
      checkLinks: PropTypes.func,
    }).isRequired,
    choiceMethods: PropTypes.shape({
      addValue: PropTypes.func.isRequired,
      renameValue: PropTypes.func.isRequired,
      patchValue: PropTypes.func.isRequired,
      removeValue: PropTypes.func.isRequired,
    }).isRequired,
    isEditMode: PropTypes.bool,
    isEntryMode: PropTypes.bool,
    admin: PropTypes.bool,
    isModule: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    isAnonymized: PropTypes.bool,
    elementModalities: PropTypes.shape().isRequired,
    element: PropTypes.shape().isRequired,
    sortedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
    user: PropTypes.shape().isRequired,
    elementSortingHandlerId: PropTypes.string.isRequired,
    modalOpened: PropTypes.bool.isRequired,
    projectEntries: PropTypes.shape(),
    projectEntryMissing: PropTypes.shape(),
    projectElement: PropTypes.shape().isRequired,
    addEntry: PropTypes.func.isRequired,
    removeEntry: PropTypes.func.isRequired,
    patchEntry: PropTypes.func.isRequired,
    resyncProjectEntries: PropTypes.func.isRequired,
  };

  static defaultProps = {
    projectElementId: null,
    moduleInstanceId: null,
    inclusion: null,
    inclusionId: null,
    parent: null,
    isEditMode: false,
    isEntryMode: false,
    isAnonymized: false,
    admin: false,
    isModule: false,
    isReadOnly: false,
    projectEntries: null,
    projectEntryMissing: null,
  };

  constructor(props) {
    super(props);
    this.timeoutHandler = new TimeoutHandler();
    this.inputs = [];
    this.modalitiesComments = {};
    this.getValuesDOM = memoize(this.loadValues);
    this.getPEntries = memoize((
      projectEntries,
      inclusionId,
      projectElementId,
      moduleInstanceId,
    ) => (
      inclusionId ? retrieveProjectEntries(
        Object.values(projectEntries), inclusionId, projectElementId, moduleInstanceId,
      ).filter((entry) => !entry.missing) : null
    ));
    this.state = {
      checkedModalities: props.projectEntries && props.inclusionId ? [
        ...this.getPEntries(
          props.projectEntries, props.inclusionId, props.projectElementId, props.moduleInstanceId,
        ).map((entry) => entry.value),
      ] : [],
      isLoading: false,
    };
  }

  checkLinks = () => {
    if (this.elBaseRef) this.elBaseRef.checkLinks();
  };

  showLoader = (show = true) => {
    if (this.elBaseRef) this.elBaseRef.showLoader(show);
  };

  isMultipleChoice = () => {
    const { element } = this.props;
    return element && element.type === ELEMENT_TYPE_MULTIPLE_CHOICES;
  };

  updateEntry = async (e, fromAsyncSelect = false) => {
    if (this.props.isEntryMode) {
      const { checked } = e.target;
      const value = Number(e.target.value);
      const { checkedModalities } = this.state;
      if (checked !== checkedModalities.includes(value)) {
        this.setState({
          checkedModalities: checked
            ? [...(this.isMultipleChoice() ? checkedModalities : []), value]
            : checkedModalities.filter((val) => val !== value),
        });
      }
      if (!fromAsyncSelect) {
        // AsyncSelect does not fire events when onChange is called
        e.persist();
      }
      if (this.isMultipleChoice()) {
        this.executeEntryChange(checked, value);
      } else {
        // Attendre avant de changer la valeur sur un radio
        this.timeoutHandler.doAfterTimeout(async () => {
          this.executeEntryChange(checked, value);
        }, 0, 500);
      }
    }
  };

  executeEntryChange = async (checked, value) => {
    // this.setState({ isLoading: true });
    const {
      projectEntries: pEntries, inclusionId, projectElement,
      moduleInstanceId, removeEntry, addEntry, patchEntry,
      methods, projectEntryMissing, resyncProjectEntries, projectElementId,
    } = this.props;
    const projectEntries = this.getPEntries(
      pEntries, inclusionId, projectElementId, moduleInstanceId,
    );
    try {
      this.showLoader();
      const values = projectEntries.map((entry) => entry.value);
      // if (checked === values.includes(value)) return;
      if (checked) {
        if (this.isMultipleChoice() || !values.length) {
          await addEntry({
            type: this.isMultipleChoice() ? ENTRY_TYPE_MULTIPLE_CHOICES
              : ENTRY_TYPE_UNIQUE_CHOICE,
            inclusion: inclusionId,
            project_element: projectElement.id,
            module: moduleInstanceId,
            value,
          });
          // The back has deleted this entry so we need to resync
          if (projectEntryMissing) {
            await resyncProjectEntries();
          }
        } else {
          const [projectEntry] = projectEntries;
          if (projectEntry) await patchEntry(projectEntry.id, { value });
        }
      } else {
        const projectEntry = projectEntries.find((pEntry) => pEntry.value === value);
        if (projectEntry) await removeEntry(projectEntry.id);
      }
      if (this.modalitiesComments[value] && checked) {
        this.modalitiesComments[value].show();
      }
      Toast.success(this.props, 'error:valid.saved');
      if (methods.checkLinks) methods.checkLinks();
      if (this.elBaseRef) this.elBaseRef.checkForCorruptedMissingDataCount();
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    } finally {
      // this.setState({ isLoading: false });
      this.showLoader(false);
    }
  }

  onSwitchChange = async (e) => {
    if (this.elBaseRef) {
      const { patchElement } = this.elBaseRef;
      const value = e.target.checked || null;
      try {
        await patchElement({ display_as_dropdown: value });
        Toast.success(this.props, 'error:valid.saved');
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      }
    }
  }

  formattedModality = (modality) => {
    const { t } = this.props;
    return {
      value: modality.id,
      label: ElementUtil.formatModalityName(modality, t),
    };
  }

  getModalities = async (search, elementModalities, sortedItems) => {
    const { element } = this.props;
    const { values } = element;
    let modalities = sortedItems.map((item) => {
      const modalityId = values.find((value) => value === extractSortingItemDigitalId(item));
      return elementModalities[modalityId];
    });
    if (search) {
      modalities = modalities.filter((modality) => (
        modality.name.toLowerCase().includes(search.toLowerCase())));
    }
    return modalities.map((modality) => this.formattedModality(modality));
  }

  getDefaultModalities = (elementModalities, checkedModalities) => {
    const modalities = Object.values(elementModalities);
    return modalities.filter((modality) => (
      checkedModalities.includes(modality.id))).map((modality) => this.formattedModality(modality));
  }

  handleSelectChange = (choices, elementModalities, checkedModalities) => {
    if (choices) {
      if (!this.isMultipleChoice()) {
        if (choices) {
          const checked = !checkedModalities.includes(choices.value);
          return {
            checked,
            value: choices.value,
          };
        }
      }
      const choicesIds = choices.map((choice) => choice.value);
      // Add a modality
      if (choices.length > checkedModalities.length) {
        const newModalityId = choicesIds.find((choiceId) => !checkedModalities.includes(choiceId));
        return {
          checked: true,
          value: newModalityId,
        };
      }
      // Remove a modality
      const removedModalityId = checkedModalities.find((modalityId) => (
        !choicesIds.includes(modalityId)));
      return {
        checked: false,
        value: removedModalityId,
      };
    }
    // When we delete the value of a unique choice (choices is null)
    // When we delete the last value of a multiplechoices (choices is null)
    return {
      checked: false,
      value: checkedModalities[0],
    };
  }

  focusOnModalityNameInput = (modalityId) => {
    const nameInputs = this.modalityNameInputs;
    if (nameInputs && nameInputs[modalityId]) {
      nameInputs[modalityId].switchToInput();
    }
  }

  loadValues(checkedModalities, element, elementModalities, sortedItems, modalOpened, isModule,
    isReadOnly, isEditMode) {
    if (element) {
      const {
        t, choiceMethods, elementSortingHandlerId, isEntryMode, isAnonymized, project,
        projectElementId,
      } = this.props;
      const { values } = element;
      const choiceCount = values.length;
      const options = {};
      if (isEditMode && !isEntryMode) {
        options.disabled = true;
        this.inputs.filter((input) => input !== null).forEach((input) => {
          // eslint-disable-next-line no-param-reassign
          input.checked = false;
        });
      }
      this.inputs = [];
      this.modalityNameInputs = [];
      const valuesDOM = sortedItems.map((item, index) => {
        const modalityId = values.find((value) => value === extractSortingItemDigitalId(item));
        const modality = elementModalities[modalityId];
        if (modality) {
          if (isEntryMode && !isAnonymized) {
            options.checked = checkedModalities.includes(modality.id);
          }
          const modalityName = ElementUtil.formatModalityName(modality, t);
          const sortingItemId = getSortingItemId(elementSortingHandlerId, modality.id);

          return (
            <DraggableComponent
              key={sortingItemId}
              draggableId={sortingItemId}
              droppableId={elementSortingHandlerId}
              index={index}
              usePortal={isModule}
              isDragDisabled={isEntryMode || (modalOpened && !isModule) || isReadOnly}
            >
              <div
                data-sorting={modality.sorting}
                key={`${projectElementId}_${modality.id}`}
                className={`custom-control custom-control-${element.id} choice-element pr-0 ${this.isMultipleChoice() ? 'custom-checkbox' : 'custom-radio'}`}
              >
                <input
                  id={`${projectElementId}_${modality.id}_input`}
                  name={projectElementId}
                  type="checkbox"
                  value={modality.id}
                  disabled={isReadOnly}
                  className="custom-control-input"
                  onChange={(e) => this.updateEntry(e)}
                  onBlur={() => {
                    const comment = this.modalitiesComments[modality.id];
                    if (comment) comment.hide();
                  }}
                  ref={(input) => {
                    this.inputs.push(input);
                  }}
                  {...options}
                />
                <label
                  className="custom-control-label mr-3"
                  htmlFor={`${projectElementId}_${modality.id}_input`}
                >
                  {
                    isEditMode ? (
                      <ElementContentEditable
                        defaultValue={modality.name}
                        className="element-edit-input modality-edit-input"
                        staticLabelClassName="element-edit-span modality-edit-span"
                        onChange={(e) => choiceMethods.renameValue(e, modality.id)}
                        placeholder={t('common:elements.placeholders.answer')}
                        autoTooltip
                        resizeInput
                        containerId={`modality-${modality.id}`}
                        ref={(ref) => { this.modalityNameInputs[modality.id] = ref; }}
                        handleInvalidValues
                        invalidValueMessage={(
                          <span>
                            {t('project:invalid-modality-name.part-1')}
                            <br />
                            {t('project:invalid-modality-name.part-2')}
                          </span>
                        )}
                      />
                    ) : (
                      <span
                        className="text-primary choice-element"
                        dangerouslySetInnerHTML={{ __html: TextUtil.escape(modalityName) }}
                      />
                    )
                  }
                  <div className="d-inline">
                    {
                      modality.comment && (
                        <ElementComment
                          comment={modality.comment}
                          className="ml-2"
                          ref={(comment) => {
                            if (modality.comment_auto_open) {
                              this.modalitiesComments[modality.id] = comment;
                            }
                          }}
                        />
                      )
                    }
                    {
                      isEditMode && (
                        <div className="value-params">
                          <div className="d-inline value-params-item">
                            <ElementCommentManager
                              element={modality}
                              elementName={
                                `${ElementUtil.formatElementName(element, t)} - ${modalityName}`
                              }
                              project={project}
                              methods={{
                                patchElement: async (patch) => choiceMethods.patchValue(
                                  modality.id,
                                  patch,
                                ),
                              }}
                              autoOpenChoice
                              hideValidationToast
                            >
                              <NewTooltip
                                content={t(`project:form.${modality.comment === '' ? 'add' : 'edit'}-response-comments`)}
                                arrow
                                distance={11}
                              >
                                <span
                                  role="button"
                                  tabIndex="-1"
                                  className="cursor-pointer"
                                >
                                  <FontAwesomeIcon icon="comment-alt-exclamation" className="text-gray-500" />
                                </span>
                              </NewTooltip>
                            </ElementCommentManager>
                          </div>
                          {
                            choiceCount > 2 && (
                              <IconConfirm
                                tooltipContent={t('project:form.delete-modality')}
                                onClick={async () => {
                                  const modalitiesCount = element.values ? element.values.length
                                    : undefined;
                                  await choiceMethods.removeValue(modality.id);
                                  if (modalitiesCount
                                    && modalitiesCount === MIN_MODALITIES_TO_SHOW_SWITCH
                                    && this.elBaseRef
                                    && element.display_as_dropdown) {
                                    await this.elBaseRef.patchElement(
                                      { display_as_dropdown: null },
                                    );
                                  }
                                }}
                                tooltipArrow
                                tooltipDistance={4.3}
                              >
                                <span
                                  role="button"
                                  tabIndex="-1"
                                  className="value-remove cursor-pointer ml-2 value-params-item"
                                >
                                  <FontAwesomeIcon icon="trash-alt" className="text-gray-500" />
                                </span>
                              </IconConfirm>
                            )
                          }
                        </div>
                      )
                    }
                    {
                      isEditMode && (
                        <NewTooltip
                          content={t('project:form.change-position')}
                        >
                          <span
                            className="value-drag-n-drop"
                          >
                            <FontAwesomeIcon
                              icon={['fas', 'grip-vertical']}
                              className="text-gray"
                              transform="shrink-3"
                            />
                          </span>
                        </NewTooltip>
                      )
                    }
                  </div>
                </label>
              </div>
            </DraggableComponent>
          );
        }
        const error = new Error('Modality not found. Should not happen.');
        ErrorUtil.handleCatched(this.props, error, false);
        return null;
      });
      return valuesDOM.sort(SortUtil.sortElement);
    }
    return null;
  }

  render() {
    const {
      t, element, projectEntries: pEntries, elementModalities, sortedItems, elementSortingHandlerId,
      modalOpened, isModule, isEditMode, isEntryMode, isReadOnly, projectEntryMissing, inclusionId,
      projectElementId, moduleInstanceId,
    } = this.props;
    const { checkedModalities } = this.state;
    const projectEntries = this.getPEntries(
      pEntries, inclusionId, projectElementId, moduleInstanceId,
    );
    const options = {};
    if (isEditMode && !isEntryMode) {
      options.disabled = true;
    }

    const modalitiesCount = element.values ? element.values.length : 0;
    const showSwitch = (isEditMode || (!isEntryMode && isReadOnly))
      && !element.implementable && (modalitiesCount >= MIN_MODALITIES_TO_SHOW_SWITCH);

    const styles = {
      ...defaultStyles,
      placeholder: (provided, state) => ({
        ...provided,
        position: 'absolute',
        top: (state.hasValue || state.selectProps.inputValue) ? -8 : '50%',
        transition: 'top 0.1s, font-size 0.1s',
        fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
        width: (state.hasValue || state.selectProps.inputValue) && 'max-content',
        color: 'var(--gray-dark)',
      }),
    };

    const missingData = (!projectEntries || !projectEntries.length) && !projectEntryMissing ? 'empty' : '';
    const qualifiedMissingData = projectEntryMissing && projectEntryMissing.missing ? 'qualified-missing' : '';

    return (
      <ElementBase
        {...this.props}
        elementCategory="entry"
        addValue={this.props.choiceMethods.addValue}
        siblingType={this.isMultipleChoice() ? {
          data: { type: ELEMENT_TYPE_UNIQUE_CHOICE },
          name: 'project:form.switch-to-type.uniquechoice-type',
        } : {
          data: { type: ELEMENT_TYPE_MULTIPLE_CHOICES },
          name: 'project:form.switch-to-type.multiplechoices-type',
        }}
        ref={(ref) => { this.elBaseRef = ref; }}
      >
        {
          (isEntryMode && element.display_as_dropdown) ? (
            <div
              className="col-12 col-sm-8 col-md-11 col-lg-10 col-xl-8"
            >
              <CustomAsyncSelect
                cacheOptions
                className={`react-select element-choice ${missingData}${qualifiedMissingData}`}
                noOptionsMessage={() => t('project:form.no-response')}
                placeholder={t('project:form.select-or-create-choice')}
                loadOptions={(search) => this.getModalities(search, elementModalities, sortedItems)}
                onChange={async (choices) => {
                  const event = {};
                  event.target = this.handleSelectChange(
                    choices, elementModalities, checkedModalities,
                  );
                  await this.updateEntry(event, true);
                }}
                value={this.getDefaultModalities(elementModalities, checkedModalities)}
                size="25"
                closeMenuOnSelect={!this.isMultipleChoice()}
                isMulti={this.isMultipleChoice()}
                isClearable={!this.isMultipleChoice()}
                formatOptionLabel={(data) => (
                  ElementUtil.formatModalityName({ name: data.label }, t)
                )}
                ref={(ref) => { this.selectRef = ref; }}
                styles={styles}
                isDisabled={isReadOnly}
              />
            </div>
          ) : (
            <DroppableComponent
              droppableId={elementSortingHandlerId}
              type={elementSortingHandlerId}
              isDropDisabled={isEntryMode || (modalOpened && !isModule) || isReadOnly}
            >
              <div
                id={`${this.props.elementId}_drag`}
                className={`col values-choice values-flex ${missingData}${qualifiedMissingData} ${this.state.isLoading ? 'loading' : ''}`}
              >
                {this.getValuesDOM(checkedModalities, element, elementModalities, sortedItems,
                  modalOpened, isModule, isReadOnly, isEditMode)}
              </div>
              {
                showSwitch && (
                  <div className="custom-control custom-switch ml-3 mt-1">
                    <input
                      className="custom-control-input display-as-dropdown-input"
                      type="checkbox"
                      id={`customSwitch-${element.id}`}
                      checked={element.display_as_dropdown || false}
                      onChange={this.onSwitchChange}
                      disabled={isReadOnly}
                    />
                    <label
                      className="custom-control-label font-italic display-as-dropdown-label"
                      htmlFor={`customSwitch-${element.id}`}
                      style={{ paddingLeft: '0.2rem', paddingTop: '0.05rem' }}
                    >
                      <div className="container">
                        <div className="row align-items-center display-as-dropdown-text">
                          {t('project:form.display-as-dropdown')}
                          <Help
                            iconClassName="ml-1 mt-1"
                            iconColor={isReadOnly ? 'text-gray' : 'text-dark-light'}
                          >
                            {t('project:form.display-as-dropdown-help')}
                          </Help>
                        </div>
                      </div>
                    </label>
                  </div>
                )
              }
            </DroppableComponent>
          )
        }
      </ElementBase>
    );
  }
}


export default ElementChoice;
