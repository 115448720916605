import fromEntries from 'fromentries';
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import { nsOptions } from '../../i18n';
import TitleCard from './TitleCard';
import VariableCard from './VariableCard';
import TextStats from './TextStats';
import { MEASUREMENTS, TITLE } from '../../constants';
import { formatChartData, getStatsElementTypeFromElement } from '../../utils/stats';
import FormattedValue from '../../utils/FormattedValue';
import DataOverviewModal from './DataOverviewModal';
import { CardLoader } from '../Loader';

const styles = (theme) => ({
  icon: {
    position: 'absolute',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(7.5),
    paddingBottom: theme.spacing(7.5),
    width: theme.spacing(12),
    height: theme.spacing(12),
    color: 'white',
    fontSize: theme.typography.pxToRem(theme.spacing(2.5)),
    background: `linear-gradient(to bottom right, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 50%, rgba(0, 0, 0, 0) 50%)`,
  },
  cardContent: {
    paddingTop: 0,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  statsContainer: {
    // Makes loader work
    position: 'relative',
    minHeight: '200px',
  },
  chartContainer: {
    height: '100%',
  },
  numberValue: {
    display: 'block',
    fontSize: theme.typography.pxToRem(75),
    lineHeight: 1,
    color: theme.palette.primary.main,
  },
  textContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  dataOverviewGrid: {
    margin: 'auto',
    marginTop: '0.3rem',
  },
});


@withTranslation('', nsOptions)
@withStyles(styles)
class ModuleCard extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    children: PropTypes.arrayOf(PropTypes.any).isRequired,
    mean: PropTypes.number,
    textStats: PropTypes.shape().isRequired,
    loadData: PropTypes.func.isRequired,
    statsLoading: PropTypes.bool,
    classes: PropTypes.shape().isRequired,
    project: PropTypes.shape().isRequired,
    canUseAdvancedTools: PropTypes.bool.isRequired,
    filtersParams: PropTypes.shape(),
    isLinkTarget: PropTypes.bool,
    elementType: PropTypes.string,
    decompositionMode: PropTypes.string,
  };

  static defaultProps = {
    mean: null,
    statsLoading: false,
    filtersParams: null,
    isLinkTarget: false,
    elementType: null,
    decompositionMode: 'none',
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  showLoader = (show = true) => this.setState({ loading: show });

  render() {
    const {
      id, name, count, children, t, classes, mean, textStats, loadData, statsLoading,
      project, canUseAdvancedTools, filtersParams, isLinkTarget, elementType, decompositionMode,
    } = this.props;
    const { loading } = this.state;

    return (
      <Card>
        <Tooltip title={t('stats:module')} placement="top">
          <div className={classes.icon}>
            <FontAwesomeIcon icon={['far', 'sitemap']} />
          </div>
        </Tooltip>
        <CardHeader
          title={name}
          titleTypographyProps={{ align: 'center', variant: 'h6' }}
        />
        <CardContent className={classes.cardContent}>
          <Grid
            container
            alignItems="center"
            spacing={4}
            className={classes.statsContainer}
          >
            {loading && <CardLoader />}
            <Grid item xs={12} md={6}>
              <Grid
                container
                direction="row"
                className={classes.chartContainer}
              >
                <Grid item xs={12}>
                  <Typography align="center">
                    <span className={classes.numberValue}>
                      <FormattedValue value={mean} />
                    </span>
                    {t('stats:entries-per-inclusion')}
                  </Typography>
                </Grid>
              </Grid>
              {
                !count && (
                  <Grid
                    container
                    direction="row"
                    className={classes.chartContainer}
                  >
                    <Grid item xs={8} className={classes.dataOverviewGrid}>
                      <DataOverviewModal
                        id={id}
                        name={name}
                        isModule
                        count={count}
                        filtersParams={filtersParams}
                        project={project}
                        isLinkTarget={isLinkTarget}
                        elementType={elementType}
                        showLoader={this.showLoader}
                        canUseAdvancedTools={canUseAdvancedTools}
                      />
                    </Grid>
                  </Grid>
                )
              }
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                direction="row"
                className={classes.textContainer}
              >
                <Grid item xs={12}>
                  <TextStats
                    id={id}
                    type={MEASUREMENTS}
                    unit={t('stats:entries-per-inclusion')}
                    count={count}
                    variableName={name}
                    filtersParams={filtersParams}
                    isModule
                    isLinkTarget={isLinkTarget}
                    project={project}
                    elementType={elementType}
                    showLoader={this.showLoader}
                    canUseAdvancedTools={canUseAdvancedTools}
                    {...textStats}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={4}>
            {
              count ? children.map((child) => {
                const type = getStatsElementTypeFromElement(child);
                let content;
                if (type === TITLE) {
                  content = <TitleCard name={child.name} level={child.level} />;
                } else if (type === null) {
                  content = null;
                } else {
                  content = (
                    <VariableCard
                      inModule
                      {...{
                        id: child.id,
                        name: child.name,
                        isLinkTarget: child.is_link_target,
                        type,
                        elementType: child.type,
                        unit: child.unit,
                        seriesNames: child.iterations.map((iteration) => iteration.name),
                        count: child.count,
                        seriesNamesCounts: fromEntries(
                          child.iterations.map(
                            (iteration) => [iteration.name, iteration.count],
                          ),
                        ),
                        iterations: child.iterations,
                        chartData: formatChartData(child),
                        textStats: {
                          count: child.count,
                          min: child.min,
                          max: child.max,
                          median: child.median,
                          mean: child.mean,
                          variance: child.variance,
                          stddev: child.stddev,
                          interquartileRange: child.interquartile_range,
                        },
                      }}
                      loadData={loadData}
                      statsLoading={statsLoading}
                      project={project}
                      canUseAdvancedTools={canUseAdvancedTools}
                      filtersParams={filtersParams}
                      decompositionMode={decompositionMode}
                    />
                  );
                }
                return <Grid item xs={12} key={child.id}>{content}</Grid>;
              }) : null
            }
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default ModuleCard;
