import fromEntries from 'fromentries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import {
  makeStyles, TableContainer, TablePagination, TableSortLabel,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';
import FilterListIcon from '@material-ui/icons/FilterList';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import Link from '@material-ui/core/Link';
import { Mutex } from 'async-mutex';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import {
  statsFiltersActions, projectUsersActions,
} from '../../redux/actions';
import api from '../../api';
import { nsOptions } from '../../i18n';
import LicenseChecker from '../LicenseChecker';
import ErrorUtil from '../../utils/ErrorUtil';
import { formatPageTitle, hasPermission } from '../../utils/data-util';
import downloadEndpoint, { NO_BOM, UTF8_BOM } from '../../utils/downloadEndpoint';
import {
  formatChartData, getStatsElementTypeFromElement, isHidden, isQuantitative,
} from '../../utils/stats';
import {
  TEAM_CENTER_TYPE, USER_CENTER_TYPE, MEMBER_CENTER_TYPE, MISC_CENTER_TYPE, getCenterId,
  getRawIdFromCenterId, isTeamCenterId, isUserCenterId, isMemberCenterId, isMiscCenterId,
  DELETED_USER_CENTER_TYPE, isDeletedUserCenterId, isDeletedMemberCenterId, getRawIdAndSuffix,
  DELETED_MEMBER_CENTER_TYPE,
  getSuffixFromCenterId,
} from '../../utils/centers';
import { getFAQArticleUrl } from '../../utils/urls';
import {
  MODULE, TITLE, CAN_VIEW_EXTERNAL_INCLUSIONS, CAN_INCLUDE,
  PROJECT_STATS_ACCESS_ALL, PROJECT_STATS_ACCESS_NONE, PROJECT_STATS_ACCESS_RESTRICTED,
  CAN_VIEW_TEAM_RESULTS, PROJECT_USER_USUAL,
} from '../../constants';
import TitleCard from './TitleCard';
import ModuleCard from './ModuleCard';
import VariableCard from './VariableCard';
import fromReduxState from '../../utils/redux';
import DataExportModal from './DataExportModal';
import { UpdatedBadge } from '../Badges';
import { checkLimitation } from '../../utils/license';

const INITIALIZING_RESULTS_ID = 0;
const ALL_RESULTS_ID = 1;
const PAGE_PROGRESS_SIZE = 60;
const BUTTON_PROGRESS_SIZE = 60;

const getActionButtonBaseStyle = () => ({
  textTransform: 'none',
  height: '100%',
});

const styles = (theme) => ({
  resultsContainer: {
    marginTop: 'calc(1rem + 8px)',
  },
  inclusionsCountContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    minHeight: '200px',
    padding: theme.spacing(2),
  },
  inclusionsCount: {
    display: 'block',
    fontSize: theme.typography.pxToRem(75),
    lineHeight: 1,
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  resultsAccessDenied: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    minHeight: '230px',
    padding: theme.spacing(2),
  },
  resultsAccessDeniedInfo: {
    color: theme.palette.grey[500],
    padding: theme.spacing(1),
  },
  foldingButton: {
    '&:focus': {
      outline: 'none',
    },
    minWidth: '20px',
    marginLeft: '20px',
    marginRight: '10px',
  },
  csvSelect: {
    ...theme.typography.body2,
    '& .MuiOutlinedInput-input': {
      padding: '18.5px 14px !important',
    },
    '& .MuiSelect-outlined': {
      paddingRight: '32px !important',
    },
  },
  csvButton: {
    ...getActionButtonBaseStyle(theme),
    backgroundColor: theme.palette.turquoise.light,
    '&:hover': {
      backgroundColor: theme.palette.turquoise.main,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  resetFiltersButton: {
    ...getActionButtonBaseStyle(theme),
  },
  resultsTypeSelect: {
    minWidth: 200,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -BUTTON_PROGRESS_SIZE / 2,
    marginLeft: -BUTTON_PROGRESS_SIZE / 2,
  },
  progress: {
    color: 'var(--newblue-1)',
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginTop: -PAGE_PROGRESS_SIZE / 2,
    marginLeft: -PAGE_PROGRESS_SIZE / 2,
    zIndex: 10000,
  },
  labelProgress: {
    color: 'var(--newblue-1)',
    position: 'fixed',
    top: '50%',
    left: '50%',
    fontSize: '1.20rem',
    transform: 'translate(-50%, 130%)',
    zIndex: 10000,
  },
  pageTab: {
    flexGrow: 1,
    minHeight: theme.spacing(8),
    '&:focus': {
      outline: 'none',
    },
  },
  centerSearchBar: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '0.5rem',
    '& .MuiInputBase-root': {
      fontSize: '0.9rem',
      height: 'calc(1.5em + 0.75rem)',
    },
    '& .MuiInputBase-input': {
      padding: '0 0.75rem 0 0.2rem !important',
      minWidth: '200px',
    },
    '& .MuiInputBase-input:placeholder-shown': {
      overflow: 'hidden !important',
      textOverflow: 'ellipsis',
    },
  },
  infoIcon: {
    color: 'var(--gray-dark)',
  },
  smallInfoIcon: {
    color: 'var(--gray-dark)',
    fontSize: '1.1rem',
    marginLeft: '0.5rem',
  },
  filterLabel: {
    display: 'flex',
    alignItems: 'center',
  },
});

const useStyles = makeStyles(styles);

const LightTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white',
  },
})(Tooltip);

const Help = (props) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={props.message}
    >
      <HelpOutlineOutlinedIcon
        className={classes.smallInfoIcon}
        fontSize="small"
      />
    </Tooltip>
  );
};

Help.propTypes = {
  message: PropTypes.string.isRequired,
};

const SelectCenter = (props) => {
  const {
    user, projectUsers, classes, teams, showResultsTypeSelect, canViewExternalInclusions,
    canViewTeamResults, statsLoading, onChange, canInclude, resultsType, teamUnfolded,
    showUserResultsOption, dataInitializing, onTeamFoldingToggle, t, deletedProjectUsers,
  } = props;
  const [centerSearch, setCenterSearch] = useState('');
  if (!showResultsTypeSelect) {
    return null;
  }
  let participants = [];
  let externalParticipants = [];
  let effectiveTeams = [];
  let externalParticipantsDeleted = [];
  let deletedMembers = [];

  if (projectUsers && projectUsers.length && canViewExternalInclusions) {
    participants = projectUsers.filter((pUser) => (
      pUser.user.id !== user.id || pUser.team_members.length > 1 // Multi-team case
    ));
    externalParticipants = participants.filter((pUser) => !pUser.team_members.length);

    // Find deleted external users
    externalParticipantsDeleted = deletedProjectUsers.filter((deletedUsr) => (
      !deletedUsr.teams || !deletedUsr.teams.length));

    // Find deleted team members and their teams
    deletedMembers = deletedProjectUsers.filter((deletedUsr) => (
      deletedUsr.teams && deletedUsr.teams.length));
    const deletedTmMemberTeams = [];
    deletedMembers.forEach((deletedTmMember) => {
      deletedTmMember.teams.forEach((teamId) => {
        if (!deletedTmMemberTeams.includes(teamId)) deletedTmMemberTeams.push(teamId);
      });
    });

    effectiveTeams = teams.filter((tm) => participants.find((pUser) => (
      pUser.teams.includes(tm.id))) || deletedTmMemberTeams.includes(tm.id));
  } else if (projectUsers && projectUsers.length && canViewTeamResults) {
    const currentProjectUser = projectUsers.find((pUser) => (
      pUser.user.id === user.id
    ));
    const filteredPerms = currentProjectUser.permissions.filter((perm) => (
      perm.name === CAN_VIEW_TEAM_RESULTS));

    // Find deleted team members according to the project user perms
    const filteredTeamIds = filteredPerms.map((perm) => perm.team);
    deletedMembers = deletedProjectUsers.filter(
      (deletedTmMember) => Boolean(
        deletedTmMember.teams.find((teamId) => filteredTeamIds.includes(teamId)),
      ),
    );
    effectiveTeams = teams.filter((tm) => filteredTeamIds.includes(tm.id));
    participants = projectUsers.filter((pUser) => (
      pUser.user.id !== user.id || pUser.team_members.length > 1 // Multi-team case
    ));
  }

  const getOptionLabel = (value) => {
    let id;
    const isDeletedUser = isDeletedUserCenterId(value);
    const isDeletedMember = isDeletedMemberCenterId(value);
    try {
      let idType = Number;
      if (isDeletedUser || isDeletedMember) idType = String;
      id = getRawIdFromCenterId(value, idType);
    } catch (error) {
      return value;
    }

    if (isMiscCenterId(value)) {
      switch (id) {
        case INITIALIZING_RESULTS_ID: return '...';
        case ALL_RESULTS_ID: return t('stats:results.all');
        default:
          console.error(`Unexpected center id ${value}.`);
          return value;
      }
    } else if (isUserCenterId(value)) {
      if (id === user.id) {
        return t('stats:results.mine');
      }
      const participant = participants.find((part) => part.user.id === id);
      if (participant) {
        return participant.user.label;
      }
      console.error(`Unable to retrieve participant with user id ${id}.`);
      return value;
    } else if (isTeamCenterId(value)) {
      const team = teams.find((tm) => tm.id === id);
      if (team) {
        return team.name;
      }
      console.error(`Unable to retrieve participant label with id ${id}.`);
      return value;
    } else if (isMemberCenterId(value)) {
      const participant = participants.find((part) => (
        part.team_members.find((member) => member.id === id)
      ));
      if (participant) {
        return participant.user.label;
      }
      console.error(`Unable to retrieve participant with member id ${id}.`);
      return value;
    } else if (isDeletedUser) {
      const deletedParticipant = externalParticipantsDeleted.find((part) => part.email === id);
      if (deletedParticipant) return deletedParticipant.label;
      console.error(`Unable to retrieve information about the user with email ${id}.`);
      return value;
    } else if (isDeletedMember) {
      const teamId = getSuffixFromCenterId(value);
      const deletedMember = deletedMembers.find((part) => part.email === id
        && part.teams.includes(teamId));
      if (deletedMember) return deletedMember.label;
      console.error(`Unable to retrieve information about the member with email ${id} (team_id: ${teamId}).`);
      return value;
    } else {
      console.error(`Unexpected center id ${value}.`);
      return value;
    }
  };

  const renderOption = (id, type = MISC_CENTER_TYPE, children = null, className = '', userInProject = true) => {
    const value = getCenterId(type, id);
    return (
      <MenuItem key={value} value={value} className={className}>
        <span className={classes.filterLabel}>
          {getOptionLabel(value)}
          {!userInProject && <Help message={t('project:user-has-left-the-project')} />}
        </span>
        {children}
      </MenuItem>
    );
  };

  const displayItem = (label) => {
    if (!centerSearch) return true;
    const labelToLowerCase = label.toLowerCase();
    const centerSearchToLowerCase = centerSearch.toLowerCase();
    if (!labelToLowerCase.includes(centerSearchToLowerCase)) return false;
    return true;
  };

  return (
    <Grid item>
      <TextField
        className={classes.resultsTypeSelect}
        value={resultsType}
        select
        disabled={statsLoading
          || (!canInclude && !canViewExternalInclusions && !canViewTeamResults)}
        onChange={onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon={['fas', 'filter']} className="text-gray-dark" />
            </InputAdornment>
          ),
        }}
        SelectProps={{
          renderValue: (value) => <span className="mr-4">{getOptionLabel(value)}</span>,
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          },
        }}
      >
        {dataInitializing && renderOption(INITIALIZING_RESULTS_ID)}
        {showUserResultsOption && renderOption(user.id, USER_CENTER_TYPE)}
        {renderOption(ALL_RESULTS_ID, MISC_CENTER_TYPE, (
          <Tooltip title={t('stats:all-results-tooltip')} placement="right">
            <span>
              <FontAwesomeIcon
                icon={['fal', 'question-circle']}
                className="text-muted ml-2 mr-4"
                transform="shrink-2"
              />
            </span>
          </Tooltip>
        ))}
        {
          (externalParticipants.length || effectiveTeams.length
            || externalParticipantsDeleted.length)
            && (
              <span>
                <ListSubheader>{t('stats:results.others')}</ListSubheader>
                <TextField
                  className={classes.centerSearchBar}
                  placeholder={t('stats:results.search-a-center')}
                  variant="outlined"
                  value={centerSearch}
                  onChange={(e) => setCenterSearch(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => e.stopPropagation()}
                  inputProps={{
                    className: 'center-search-results',
                  }}
                />
              </span>
            )
        }
        {
          effectiveTeams.filter(
            (tm) => displayItem(tm.name)
              || (projectUsers && projectUsers.length
                && projectUsers.find((pUser) => pUser.teams.includes(tm.id)
                  && displayItem(pUser.user.label)))
              || (deletedMembers.find((deletedMember) => deletedMember.teams.includes(tm.id)
                && displayItem(deletedMember.label))),
          ).map((tm) => [
            renderOption(tm.id, TEAM_CENTER_TYPE, (
              <Tooltip
                title={t(`common:centers.${teamUnfolded[tm.id] ? 'hide-team-members' : 'show-team-members'}`)}
                placement="right"
              >
                <Button
                  className={classes.foldingButton}
                  onClick={(event) => onTeamFoldingToggle(event, tm.id)}
                >
                  <FontAwesomeIcon
                    icon={['far', teamUnfolded[tm.id] ? 'chevron-down' : 'chevron-right']}
                    transform="shrink-2"
                  />
                </Button>
              </Tooltip>
            )),
            teamUnfolded[tm.id] && tm.members.filter((memberId) => participants.find((pUser) => (
              pUser.team_members.find((member) => member.id === memberId)
            ))).map((memberId) => (
              renderOption(memberId, MEMBER_CENTER_TYPE, null, 'pl-5')
            )),
            teamUnfolded[tm.id] && deletedMembers.filter((deletedMember) => (
              deletedMember.teams.includes(tm.id) && displayItem(deletedMember.label)
            )).map((deletedMember) => renderOption(
              getRawIdAndSuffix(deletedMember.email, tm.id),
              DELETED_MEMBER_CENTER_TYPE,
              null,
              'pl-5 font-italic text-gray-dark',
              false,
            )),
          ])
        }
        {externalParticipants.filter((p) => displayItem(p.user.label)).map(
          (part) => renderOption(part.user.id, USER_CENTER_TYPE),
        )}
        {externalParticipantsDeleted.filter((p) => displayItem(p.label)).map(
          (part) => renderOption(part.email, DELETED_USER_CENTER_TYPE, null, 'font-italic text-gray-dark', false),
        )}
      </TextField>
    </Grid>
  );
};

SelectCenter.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
  projectUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  deletedProjectUsers: PropTypes.arrayOf(PropTypes.shape()),
  teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func,
  showResultsTypeSelect: PropTypes.bool.isRequired,
  canViewExternalInclusions: PropTypes.bool.isRequired,
  canViewTeamResults: PropTypes.bool.isRequired,
  statsLoading: PropTypes.bool.isRequired,
  canInclude: PropTypes.bool.isRequired,
  resultsType: PropTypes.string.isRequired,
  teamUnfolded: PropTypes.shape().isRequired,
  dataInitializing: PropTypes.bool.isRequired,
  onTeamFoldingToggle: PropTypes.func,
  showUserResultsOption: PropTypes.bool.isRequired,
};

SelectCenter.defaultProps = {
  deletedProjectUsers: [],
  onChange: () => {},
  onTeamFoldingToggle: () => {},
};


const mapStateToProps = (state) => ({
  statsFilters: state.statsFilters,
  user: state.auth.authUser,
  projectUsers: state.projectUsers,
  teams: state.teams,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchProjectUsers: async () => dispatch(projectUsersActions.list({
    project: ownProps.project.id,
    confirmed: true,
    admin: ownProps.admin,
  }, {
    pagination: 'no',
  })),
  removeAllFilters: () => dispatch(statsFiltersActions.deleteAllFilters()),
});

@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
@withStyles(styles)
class ProjectResults extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape().isRequired,
    project: PropTypes.shape().isRequired,
    statsFilters: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
    classes: PropTypes.shape().isRequired,
    projectUsers: PropTypes.shape().isRequired,
    teams: PropTypes.shape().isRequired,
    fetchProjectUsers: PropTypes.func.isRequired,
    removeAllFilters: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      canViewResults: true,
      dataInitializing: true,
      dataLoading: true,
      page: null,
      search: '',
      stats: null,
      resultsType: getCenterId(MISC_CENTER_TYPE, INITIALIZING_RESULTS_ID),
      showUserResultsOption: true,
      showCsvExportModal: false,
      csvExportLoading: false,
      teamUnfolded: {},
      selectedDecomposition: 'none',
      decompositionPage: 0,
      decompositionRowsPerPage: 5,
      decompositionOrder: 'desc',
      decompositionOrderBy: 'count',
    };

    this.searchTimeout = -1;
    this.mutex = new Mutex();
    this.toProjectUsersArray = fromReduxState((projectUsers) => (
      Object.values(projectUsers).filter((pUser) => (
        pUser.confirmed && pUser.type === PROJECT_USER_USUAL))
    ));
    this.toTeamsArray = fromReduxState((teams) => Object.values(teams));
  }

  componentDidMount() {
    this.props.removeAllFilters();
    this.fetchData();
  }

  onPageChange = (event, newValue) => {
    this.setState({ page: newValue });
  };

  onTeamFoldingToggle = (event, teamId) => {
    event.stopPropagation();
    this.setState((prevState) => ({
      teamUnfolded: {
        ...prevState.teamUnfolded,
        [teamId]: !prevState.teamUnfolded[teamId],
      },
    }));
  };

  onOpenCsvExport = () => {
    if (checkLimitation(this.props.project.limitations, 'can_export_results')) {
      this.setState({ showCsvExportModal: true });
    }
  }

  onCloseCsvExport = () => {
    this.setState({ showCsvExportModal: false });
  };

  onCsvExport = async (exportOptions) => {
    const { project } = this.props;
    this.setState({ csvExportLoading: true });
    this.onCloseCsvExport();
    const isZip = !exportOptions.flat;
    try {
      await downloadEndpoint(
        `projects/${project.id}/export`,
        'post',
        exportOptions,
        this.queryParams,
        isZip ? NO_BOM : UTF8_BOM,
        'arraybuffer',
      );
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      this.setState({ csvExportLoading: false });
    }
  };

  onChangeSearch = (event) => {
    const search = event.currentTarget.value;
    this.setState({ search });
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(this.loadData, 500);
  };

  onResetFilters = () => {
    this.props.removeAllFilters();
    this.loadData();
  }

  onResultsTypeChange = async (event) => {
    if (!this.mutex.isLocked()) {
      const release = await this.mutex.acquire();
      try {
        const { statsFilters, removeAllFilters } = this.props;
        const { value } = event.target;
        if (value) {
          await new Promise((resolve) => this.setState({ resultsType: value }, () => resolve()));
          if (Object.values(statsFilters).length && !this.canUseAdvancedTools) {
            removeAllFilters();
          }
          await this.loadData();
        }
      } finally {
        release();
      }
    }
  }

  getPageStats(stats, pageId) {
    let pageStats;
    if (stats !== null && pageId !== null) {
      pageStats = stats.find((data) => data.id === pageId);
    }
    return pageStats || null;
  }

  get pageStats() {
    const { stats, page } = this.state;
    return this.getPageStats(stats, page);
  }

  get teamId() {
    const { team } = this.queryParams;
    return team;
  }

  get count() {
    const { pageStats } = this;
    if (pageStats === null) {
      return null;
    }
    return pageStats.count;
  }

  get statsLoading() {
    const { dataLoading, stats } = this.state;
    return dataLoading || stats === null;
  }

  get canViewExternalInclusions() {
    const { projectUsers: pUsers, user } = this.props;
    const projectUsers = this.toProjectUsersArray(pUsers);
    const currentProjectUser = projectUsers.find((pUser) => pUser.user.id === user.id);
    return hasPermission(currentProjectUser, CAN_VIEW_EXTERNAL_INCLUSIONS);
  }

  get canViewTeamResults() {
    const { projectUsers: pUsers, user } = this.props;
    const projectUsers = this.toProjectUsersArray(pUsers);
    const currentProjectUser = projectUsers.find((pUser) => pUser.user.id === user.id);
    return hasPermission(currentProjectUser, CAN_VIEW_TEAM_RESULTS);
  }

  get canInclude() {
    const { projectUsers: pUsers, user } = this.props;
    const projectUsers = this.toProjectUsersArray(pUsers);
    const currentProjectUser = projectUsers.find((pUser) => pUser.user.id === user.id);
    return hasPermission(currentProjectUser, CAN_INCLUDE);
  }

  get canUseAdvancedTools() {
    const { user, projectUsers: pUsers, teams: tms } = this.props;
    const { resultsType } = this.state;
    const projectUsers = this.toProjectUsersArray(pUsers);
    const teams = this.toTeamsArray(tms);
    if (resultsType === getCenterId(USER_CENTER_TYPE, user.id) || this.canViewExternalInclusions) {
      return true;
    }
    const rawId = getRawIdFromCenterId(resultsType);
    const currentProjectUser = projectUsers.find((pUser) => pUser.user.id === user.id);
    if (isTeamCenterId(resultsType)) {
      return hasPermission(currentProjectUser, CAN_VIEW_TEAM_RESULTS, rawId);
    }
    if (isMemberCenterId(resultsType)) {
      const team = teams.find((tm) => tm.members.includes(rawId));
      return team && hasPermission(currentProjectUser, CAN_VIEW_TEAM_RESULTS, team.id);
    }
    return false;
  }

  get canViewResults() {
    const { project } = this.props;
    if (project.is_paused) return false;
    if (project.stats_access === PROJECT_STATS_ACCESS_ALL) {
      return true;
    } if (project.stats_access === PROJECT_STATS_ACCESS_NONE) {
      return false;
    } if (project.stats_access === PROJECT_STATS_ACCESS_RESTRICTED) {
      return this.canViewExternalInclusions;
    }
    console.error(`Unknown project stats access (${project.stats_access}).`);
    return false;
  }

  get disabledCsvExport() {
    if (this.state.csvExportLoading || this.statsLoading) {
      return true;
    }
    const { projectUsers: pUsers, user, teams: tms } = this.props;
    const { resultsType } = this.state;
    const projectUsers = this.toProjectUsersArray(pUsers);
    const teams = this.toTeamsArray(tms);
    if (projectUsers && projectUsers.length) {
      if (resultsType === getCenterId(USER_CENTER_TYPE, user.id)
        || this.canViewExternalInclusions) return false;
      const rawId = getRawIdFromCenterId(resultsType);
      const currentProjectUser = projectUsers.find((pUser) => pUser.user.id === user.id);
      if (isTeamCenterId(resultsType)) {
        return !hasPermission(currentProjectUser, CAN_VIEW_TEAM_RESULTS, rawId);
      }
      if (isMemberCenterId(resultsType)) {
        const team = teams.find((tm) => tm.members.includes(rawId));
        return !team || !hasPermission(currentProjectUser, CAN_VIEW_TEAM_RESULTS, team.id);
      }
    }
    return true;
  }

  get showResultsTypeSelect() {
    const { project } = this.props;
    return project.is_shared;
  }

  get queryParams() {
    const {
      statsFilters, teams: tms, projectUsers: pUsers, project,
    } = this.props;
    const { resultsType, search } = this.state;
    const params = {};
    const projectUsers = this.toProjectUsersArray(pUsers);
    const teams = this.toTeamsArray(tms);

    const isDeletedUser = isDeletedUserCenterId(resultsType);
    const isDeletedMember = isDeletedMemberCenterId(resultsType);
    const isDeletedCenter = isDeletedUser || isDeletedMember;

    const resultsTypeId = getRawIdFromCenterId(resultsType, isDeletedCenter ? String : Number);

    if (isUserCenterId(resultsType)) {
      params.user = resultsTypeId;
    } else if (isTeamCenterId(resultsType)) {
      params.team = resultsTypeId;
    } else if (isMemberCenterId(resultsType)) {
      const projectUser = projectUsers.find((pUser) => (
        pUser.team_members.find((mb) => mb.id === resultsTypeId)
      ));
      const team = teams.find((tm) => tm.members.includes(resultsTypeId));
      try {
        params.user = projectUser.user.id;
        params.team = team.id;
      } catch (error) {
        console.error(error);
      }
    } else if (isDeletedCenter) {
      params.creator_email = resultsTypeId;
      if (isDeletedMember && project) {
        const teamId = getSuffixFromCenterId(resultsType);
        params.team = teamId;
      }
    }

    if (search !== '') {
      params.search = search;
    }

    params.filters = fromEntries(Object.values(statsFilters).map((filter) => {
      const data = { value: filter.value };
      if (filter.lowerBound !== undefined) {
        data.lower_bound = filter.lowerBound;
      }
      if (filter.upperBound !== undefined) {
        data.upper_bound = filter.upperBound;
      }
      return [filter.targetId, data];
    }));

    return params;
  }

  fetchData = async () => {
    const { project, user, fetchProjectUsers } = this.props;

    try {
      this.setState({ dataLoading: true });

      await fetchProjectUsers();

      if (!this.canViewResults) {
        this.setState({ dataInitializing: false, dataLoading: false, canViewResults: false });
        return;
      }

      let resultsType = getCenterId(USER_CENTER_TYPE, user.id);
      let showUserResultsOption = true;

      if (!this.canInclude) {
        const count = await api.count('inclusions', {
          project: project.id, creator: user.id, is_test: false,
        });
        if (count === 0) {
          resultsType = getCenterId(MISC_CENTER_TYPE, ALL_RESULTS_ID);
          showUserResultsOption = false;
        }
      }

      await new Promise((resolve) => (
        this.setState({ resultsType, showUserResultsOption }, () => resolve())
      ));

      await this.loadData();
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      this.setState({ dataInitializing: false, dataLoading: false });
    }
  };

  loadData = async () => {
    const { project } = this.props;
    const { page } = this.state;

    try {
      this.setState({ dataLoading: true });

      const [stats, centerStats, userStats] = await Promise.all([
        api.requestData(
          `projects/${project.id}/stats`,
          null,
          'post',
          undefined,
          this.queryParams,
        ),
        api.requestData(
          `projects/${project.id}/center-stats`,
          null,
          'post',
          undefined,
          this.queryParams,
        ),
        api.requestData(
          `projects/${project.id}/user-stats`,
          null,
          'post',
          undefined,
          this.queryParams,
        ),
      ]);

      const newState = { stats, centerStats, userStats };

      if (this.getPageStats(stats, page) === null && stats.length >= 1) {
        newState.page = stats[0].id;
      }
      this.setState(newState);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      this.setState({ dataLoading: false });
    }
  };

  handleDecompositionChange = (event) => {
    this.setState({ selectedDecomposition: event.target.value });
  };

  handleRequestSort = (property) => {
    const { decompositionOrderBy, decompositionOrder } = this.state;
    const isAsc = decompositionOrderBy === property && decompositionOrder === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    this.setState({
      decompositionOrder: newOrder,
      decompositionOrderBy: property,
    });
  };

  handleChangeDecompositionPage = (event, newPage) => {
    this.setState({ decompositionPage: newPage });
  };

  handleChangeDecompositionRowsPerPage = (event) => {
    this.setState({
      decompositionRowsPerPage: parseInt(event.target.value, 10),
      decompositionPage: 0,
    });
  };

  descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  }

  // eslint-disable-next-line react/sort-comp
  getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  stableSort(array, comparator) {
    const stabilized = array.map((el, index) => [el, index]);
    stabilized.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilized.map((el) => el[0]);
  }

  renderDecompositionTable(
    breakdownArray,
    decompositionPage,
    decompositionRowsPerPage,
    decompositionOrder,
    decompositionOrderBy,
  ) {
    const { t } = this.props;

    if (!Array.isArray(breakdownArray)) {
      return null;
    }

    const sortedData = this.stableSort(
      breakdownArray,
      this.getComparator(decompositionOrder, decompositionOrderBy),
    );

    const paginatedData = sortedData.slice(
      decompositionPage * decompositionRowsPerPage,
      decompositionPage * decompositionRowsPerPage + decompositionRowsPerPage,
    );

    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={decompositionOrderBy === 'name' ? decompositionOrder : false}>
                <TableSortLabel
                  active={decompositionOrderBy === 'name'}
                  direction={decompositionOrderBy === 'name' ? decompositionOrder : 'asc'}
                  onClick={() => this.handleRequestSort('name')}
                >
                  {t('stats:decomposition.name')}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={decompositionOrderBy === 'count' ? decompositionOrder : false}>
                <TableSortLabel
                  active={decompositionOrderBy === 'count'}
                  direction={decompositionOrderBy === 'count' ? decompositionOrder : 'asc'}
                  onClick={() => this.handleRequestSort('count')}
                >
                  {t('stats:inclusions.title')}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {paginatedData.map((row) => (
              <TableRow key={row.name}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={breakdownArray.length}
          page={decompositionPage}
          onChangePage={this.handleChangeDecompositionPage}
          rowsPerPage={decompositionRowsPerPage}
          onChangeRowsPerPage={this.handleChangeDecompositionRowsPerPage}
        />
      </TableContainer>
    );
  }

  renderSearchForm() {
    const { t } = this.props;
    const { search } = this.state;
    return (
      <Grid item>
        <TextField
          placeholder={t('stats:search')}
          value={search}
          onChange={this.onChangeSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className="text-gray-dark" />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    );
  }

  renderResultsTypeSelect() {
    const {
      user, projectUsers: pUsers, classes, teams: tms, t, project,
    } = this.props;
    const {
      resultsType, showUserResultsOption, dataInitializing, teamUnfolded,
    } = this.state;
    const projectUsers = this.toProjectUsersArray(pUsers);
    const teams = this.toTeamsArray(tms);
    return (
      <SelectCenter
        t={t}
        showResultsTypeSelect={this.showResultsTypeSelect}
        user={user}
        projectUsers={projectUsers}
        teams={teams}
        classes={classes}
        resultsType={resultsType}
        showUserResultsOption={showUserResultsOption}
        dataInitializing={dataInitializing}
        teamUnfolded={teamUnfolded}
        canInclude={this.canInclude}
        canViewExternalInclusions={this.canViewExternalInclusions}
        canViewTeamResults={this.canViewTeamResults}
        statsLoading={this.statsLoading}
        onChange={this.onResultsTypeChange}
        onTeamFoldingToggle={this.onTeamFoldingToggle}
        deletedProjectUsers={project ? project.deleted_users_with_inclusions.map((deletedUsr) => (
          {
            label: deletedUsr.creator_name || deletedUsr.creator_email,
            email: deletedUsr.creator_email,
            teams: deletedUsr.teams,
          }
        )) : []}
      />
    );
  }

  renderDecompositionSelect() {
    const { classes, t } = this.props;
    const { selectedDecomposition } = this.state;

    // eslint-disable-next-line max-len
    const isProjectResults = this.state.resultsType === getCenterId(MISC_CENTER_TYPE, ALL_RESULTS_ID);
    const isTeamResults = isTeamCenterId(this.state.resultsType);

    let decompositionOptions = [];
    if (isProjectResults) {
      decompositionOptions = [
        { value: 'none', label: t('stats:decomposition.no-decomp') },
        { value: 'by_center', label: t('stats:decomposition.by-center') },
        { value: 'by_user', label: t('stats:decomposition.by-user') },
      ];
    } else if (isTeamResults) {
      decompositionOptions = [
        { value: 'none', label: t('stats:decomposition.no-decomp') },
        { value: 'by_user', label: t('stats:decomposition.by-user') },
      ];
    }

    if (!decompositionOptions.length) {
      return null;
    }

    return (
      <Grid item>
        <TextField
          className={classes.resultsTypeSelect}
          select
          value={selectedDecomposition}
          onChange={this.handleDecompositionChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={['fas', 'th-list']} className="text-gray-dark" />
              </InputAdornment>
            ),
          }}
          SelectProps={{
            renderValue: (value) => (
              <span className="mr-4">
                {decompositionOptions.find((opt) => opt.value === value)?.label || value}
              </span>
            ),
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          }}
        >
          {decompositionOptions.map((opt) => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    );
  }

  renderResetFilters() {
    const { statsFilters, classes, t } = this.props;
    const filtersCount = Object.keys(statsFilters).length;
    const tooltipContent = filtersCount ? Object.values(statsFilters).map((filter) => (
      <div key={filter.targetId}>
        {`${filter.targetName}${t('common:colon')} ${filter.formattedValue}`}
      </div>
    )) : '';
    return (
      <Grid item>
        <Tooltip title={tooltipContent}>
          <Badge color="primary" badgeContent={filtersCount}>
            <Button
              className={classes.resetFiltersButton}
              variant="contained"
              fullWidth
              startIcon={<FilterListIcon />}
              disabled={this.statsLoading || !filtersCount}
              onClick={this.onResetFilters}
            >
              { t('stats:reset-filters') }
            </Button>
          </Badge>
        </Tooltip>
      </Grid>
    );
  }

  renderInfoTooltip() {
    const { t, i18n, classes } = this.props;

    return (
      <Grid item>
        <LightTooltip
          title={(
            <Link
              href={getFAQArticleUrl(i18n.language, 9)}
              target="_blank"
              rel="noopener noreferrer"
              underline="always"
            >
              {t('common:discover-features')}
              <FontAwesomeIcon
                className="ml-1"
                icon={['far', 'external-link']}
                transform="shrink-3 down-1"
              />
            </Link>
          )}
          placement="right"
          interactive
        >
          <HelpOutlineOutlinedIcon
            className={classes.infoIcon}
          />
        </LightTooltip>
      </Grid>
    );
  }

  renderCsvExport() {
    const {
      classes, t, project,
    } = this.props;
    const { csvExportLoading, showCsvExportModal } = this.state;

    return (
      <Grid item>
        <div style={{ position: 'relative' }}>
          <LicenseChecker
            limName="can_export_results"
            limitations={project.limitations}
          >
            <div>
              <Button
                variant="contained"
                color="primary"
                disabled={this.disabledCsvExport}
                className={classes.csvButton}
                startIcon={<FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />}
                aria-controls="csv-export-menu"
                aria-haspopup="dialog"
                onClick={this.onOpenCsvExport}
              >
                {t('stats:data-export.button')}
              </Button>
              <UpdatedBadge className="csv-export-badge">
                <span />
              </UpdatedBadge>
            </div>
          </LicenseChecker>
          {csvExportLoading && (
            <CircularProgress
              size={BUTTON_PROGRESS_SIZE}
              className={classes.buttonProgress}
            />
          )}
        </div>
        <DataExportModal
          onClose={this.onCloseCsvExport}
          onValidate={this.onCsvExport}
          open={showCsvExportModal}
        />
      </Grid>
    );
  }

  renderInclusionsCount() {
    const { classes, t } = this.props;
    const {
      centerStats,
      userStats,
      selectedDecomposition,

      decompositionPage = 0,
      decompositionRowsPerPage = 5,
      decompositionOrder = 'desc',
      decompositionOrderBy = 'count',
    } = this.state;

    if (!centerStats || !userStats) {
      return null;
    }

    let activeStats;
    if (selectedDecomposition === 'by_user') {
      activeStats = userStats;
    } else {
      activeStats = centerStats;
    }

    const totalInclusions = activeStats.count || 0;
    const breakdownArray = activeStats.center_inclusions_breakdown || [];

    let decompositionLabel = t('stats:inclusions.count');
    if (selectedDecomposition === 'by_center') {
      decompositionLabel = t('stats:inclusions.by-center');
    } else if (selectedDecomposition === 'by_user') {
      decompositionLabel = t('stats:inclusions.by-user');
    }

    return (
      <Grid item>
        <Paper className={classes.inclusionsCountContainer}>
          <Typography variant="h5" align="center">
            {decompositionLabel}
          </Typography>

          <span className={classes.inclusionsCount}>
            {totalInclusions}
          </span>

          {selectedDecomposition !== 'none' && (
            this.renderDecompositionTable(
              breakdownArray,
              decompositionPage,
              decompositionRowsPerPage,
              decompositionOrder,
              decompositionOrderBy,
            )
          )}
        </Paper>
      </Grid>
    );
  }


  renderPageSelect() {
    const { t, classes } = this.props;
    const { stats, page } = this.state;
    return stats && (
      <Paper>
        <Tabs value={page} onChange={this.onPageChange} variant="scrollable">
          {
            stats.map((pageData) => (
              <Tab
                key={pageData.id}
                label={formatPageTitle({ title: pageData.name }, t)}
                value={pageData.id}
                className={classes.pageTab}
              />
            ))
          }
        </Tabs>
      </Paper>
    );
  }

  renderCard = (elementStats) => {
    const { project } = this.props;
    const type = getStatsElementTypeFromElement(elementStats);
    let chartData;
    switch (type) {
      case TITLE:
        return <TitleCard {...elementStats} />;

      case MODULE:
        return (
          <ModuleCard
            id={elementStats.id}
            name={elementStats.name}
            isLinkTarget={elementStats.is_link_target}
            seriesNames={['count']}
            count={elementStats.count}
            mean={elementStats.mean}
            textStats={{
              count: elementStats.count,
              min: elementStats.min,
              max: elementStats.max,
              median: elementStats.median,
              mean: elementStats.mean,
              variance: elementStats.variance,
              stddev: elementStats.stddev,
              interquartileRange: elementStats.interquartile_range,
            }}
            loadData={this.loadData}
            statsLoading={this.statsLoading}
            project={project}
            canUseAdvancedTools={this.canUseAdvancedTools}
            filtersParams={this.queryParams}
            elementType={elementStats.type}
            decompositionMode={this.state.selectedDecomposition}
          >
            {elementStats.children}
          </ModuleCard>
        );
      case null:
        return null;
      default:
        chartData = formatChartData(elementStats);
        return (
          <VariableCard
            id={elementStats.id}
            name={elementStats.name}
            isLinkTarget={elementStats.is_link_target}
            type={type}
            elementType={elementStats.type}
            unit={elementStats.unit}
            seriesNames={['value']}
            count={elementStats.count}
            seriesNamesCounts={
              isQuantitative(type)
                ? {}
                : fromEntries(chartData.map((row) => [row.name, row.value]))
            }
            iterations={elementStats.iterations}
            chartData={chartData}
            textStats={{
              count: elementStats.count,
              min: elementStats.min,
              max: elementStats.max,
              median: elementStats.median,
              mean: elementStats.mean,
              variance: elementStats.variance,
              stddev: elementStats.stddev,
              interquartileRange: elementStats.interquartile_range,
            }}
            loadData={this.loadData}
            statsLoading={this.statsLoading}
            project={project}
            canUseAdvancedTools={this.canUseAdvancedTools}
            filtersParams={this.queryParams}
            centerVariableData={this.state.centerVariableData}
            teamId={this.teamId}
            decompositionMode={this.state.selectedDecomposition}
          />
        );
    }
  };

  renderCards() {
    const { t } = this.props;
    return (
      <>
        <Grid item xs={12}>
          {this.renderInclusionsCount()}
        </Grid>
        <Grid item xs={12}>
          {this.renderPageSelect()}
        </Grid>
        {
          this.pageStats && this.pageStats.children && this.pageStats.children.filter(
            (elementStats) => !isHidden(elementStats),
          ).map((elementStats) => (
            <Grid item xs={12} key={elementStats.id}>
              {this.renderCard(elementStats)}
            </Grid>
          ))
        }
        {
          !this.pageStats && !this.statsLoading && (
            <Grid item xs={12}>{t('stats:empty')}</Grid>
          )
        }
      </>
    );
  }

  renderResultsAccessDenied() {
    const { classes, t } = this.props;
    return (
      <Grid item>
        <Paper className={classes.resultsAccessDenied}>
          <Typography variant="h6" align="center">
            {t('stats:access-denied')}
          </Typography>
          <Typography
            className={classes.resultsAccessDeniedInfo}
            variant="subtitle1"
            align="center"
          >
            {t('stats:access-denied-info')}
          </Typography>
        </Paper>
      </Grid>
    );
  }

  render() {
    const { classes, t } = this.props;
    const { canViewResults } = this.state;

    if (!canViewResults) {
      return this.renderResultsAccessDenied();
    }

    return (
      <>
        { this.statsLoading && (
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item>
                <CircularProgress
                  size={PAGE_PROGRESS_SIZE}
                  thickness={4.5}
                  className={classes.progress}
                />
              </Grid>
              <Grid item>
                <span className={classes.labelProgress}>
                  {t('common:data-loading')}
                </span>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={4} direction="column">
          <Grid item className={classes.resultsContainer}>
            <Hidden smUp>
              <Grid container spacing={2} direction="column" wrap="nowrap">
                {this.renderResultsTypeSelect()}
                {this.renderDecompositionSelect()}
                {this.renderSearchForm()}
                {this.renderResetFilters()}
                {this.renderCsvExport()}
                {this.renderInfoTooltip()}
              </Grid>
            </Hidden>
            <Hidden only={['xs', 'md', 'lg', 'xl']}>
              <Grid container spacing={4} alignItems="center" wrap="nowrap">
                {this.renderResultsTypeSelect()}
                {this.renderDecompositionSelect()}
                {this.renderSearchForm()}
              </Grid>
              <Grid container spacing={4} alignItems="center" wrap="nowrap">
                {this.renderResetFilters()}
                {this.renderCsvExport()}
                {this.renderInfoTooltip()}
              </Grid>
            </Hidden>
            <Hidden only={['xs', 'sm', 'lg', 'xl']}>
              <Grid container spacing={4} alignItems="center" wrap="nowrap">
                {this.renderResultsTypeSelect()}
                {this.renderDecompositionSelect()}
                {this.renderSearchForm()}
                {this.renderResetFilters()}
              </Grid>
              <Grid container spacing={4} alignItems="center" wrap="nowrap">
                {this.renderCsvExport()}
                {this.renderInfoTooltip()}
              </Grid>
            </Hidden>
            <Hidden mdDown>
              <Grid container spacing={4} alignItems="center" wrap="nowrap">
                {this.renderResultsTypeSelect()}
                {this.renderDecompositionSelect()}
                {this.renderSearchForm()}
                {this.renderResetFilters()}
                {this.renderCsvExport()}
                {this.renderInfoTooltip()}
              </Grid>
            </Hidden>
          </Grid>
          {this.renderCards()}
        </Grid>
      </>
    );
  }
}

export default ProjectResults;
