import {
  faTrashAlt as falTrashAlt,
  faSignIn as falSignIn,
  faEmptySet as falEmptySet,
  faAngleDown as falAngleDown,
  faPaperPlane as falPaperPlane,
  faBan as falBan,
  faChevronLeft as falChevronLeft,
  faChevronRight as falChevronRight,
  faCircle as falCircle,
  faPlus as falPlus,
  faSignOut as falSignOut,
  faPencilAlt as falPencilAlt,
  faCheck as falCheck,
  faPen as falPen,
  faArrowToBottom as falArrowToBottom,
  faEnvelope as falEnvelope,
  faEnvelopeOpenText as falEnvelopeOpenText,
  faBallotCheck as falBallotCheck,
  faUserInjured as falUserInjured,
  faMars as falMars,
  faVenus as falVenus,
  faFilePdf as falFilePdf,
  faFileExport as falFileExport,
  faTimes as falTimes,
  faQuestionCircle as falQuestionCircle,
  faHandPaper as falHandPaper,
  faPause as falPause,
  faCopy as falCopy,
  faSpellCheck as falSpellCheck,
  faEllipsisH as falEllipsisH,
  faHome as falHome,
  faUsers as falUsers,
  faKey as falKey,
  faGlobe as falGlobe,
  faHistory as falHistory,
  faMapMarkerAlt as falMapMarkerAlt,
  faClone as falClone,
  faMeh as falMeh,
  faFileCsv as falFileCsv,
  faChartNetwork as falChartNetwork,
  faUser as falUser,
  faFileUpload as falFileUpload,
  faCreditCard as falCreditCard,
  faUserCrown as falUserCrown,
  faSignOutAlt as falSignOutAlt,
  faSquare as falSquare,
  faLevelUpAlt as falLevelUpAlt,
  faUserClock as falUserClock,
  faThList as falThList,
  faLink as falLink,
  faArchive as falArchive,
} from '@fortawesome/pro-light-svg-icons';

import {
  faCalendarAlt as farCalendarAlt,
  faDotCircle as farDotCircle,
  faCheckSquare as farCheckSquare,
  faPrescriptionBottle as farPrescriptionBottle,
  faSitemap as farSitemap,
  faTags as farTags,
  faSearch as farSearch,
  faCertificate as farCertificate,
  faListAlt as farListAlt,
  faCalculatorAlt as farCalculatorAlt,
  faEdit as farEdit,
  faHeading as farHeading,
  faText as farText,
  faMinus as farMinus,
  faLevelDownAlt as farLevelDownAlt,
  faAngleDown as farAngleDown,
  faTimes as farTimes,
  faBars as farBars,
  faExternalLink as farExternalLink,
  faChevronDown as farChevronDown,
  faChevronRight as farChevronRight,
  faChevronLeft as farChevronLeft,
  faSpellCheck as farSpellCheck,
  faHistory as farHistory,
  faAngleDoubleRight as farAngleDoubleRight,
  faUserShield as farUserShield,
  faKey as farKey,
  faClone as farClone,
  faTrashAlt as farTrashAlt,
  faArrowToBottom as farArrowToBottom,
  faEnvelope as farEnvelope,
  faGlobe as farGlobe,
  faCommentAltExclamation as farCommentAltExclamation,
  faSquare as farSquare,
  faCheck as farCheck,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faQuestionCircle as fasQuestionCircle,
  faPen as fasPen,
  faCheck as fasCheck,
  faInfoCircle as fasInfoCircle,
  faLockAlt as fasLockAlt,
  faFilter as fasFilter,
  faBallotCheck as fasBallotCheck,
  faUserInjured as fasUserInjured,
  faCircle as fasCircle,
  faTrashAlt as fasTrashAlt,
  faPlus as fasPlus,
  faAsterisk as fasAsterisk,
  faArrowsAlt as fasArrowsAlt,
  faSquare as fasSquare,
  faLevelUpAlt as fasLevelUpAlt,
  faLevelDownAlt as fasLevelDownAlt,
  faListUl as fasListUl,
  faTimes as fasTimes,
  faEye as fasEye,
  faEyeSlash as fasEyeSlash,
  faClock as fasClock,
  faUserTie as fasUserTie,
  faCheckCircle as fasCheckCircle,
  faEnvelope as fasEnvelope,
  faExclamationTriangle as fasExclamationTriangle,
  faClone as fasClone,
  faRepeat as fasRepeat,
  faSortAlt as fasSortAlt,
  faSearch as fasSearch,
  faGripVertical as fasGripVertical,
  faPencil as fasPencil,
  faHistory as fasHistory,
  faArrowAltSquareRight as fasArrowAltSquareRight,
  faUserShield as fasUserShield,
  faAngleDown as fasAngleDown,
  faStar as fasStar,
  faCommentAltExclamation as fasCommentAltExclamation,
  faFileCertificate as fasFileCertificate,
  faPlusCircle as fasPlusCircle,
  faBook as fasBook,
  faArchive as fasArchive,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faExclamationTriangle as fadExclamationTriangle,
  faGift as fadGift,
  faUserShield as fadUserShield,
} from '@fortawesome/pro-duotone-svg-icons';

export default [
  falTrashAlt, falSignIn, falEmptySet, falAngleDown, falPaperPlane, falBan, falChevronLeft,
  falChevronRight, falCircle, falPlus, falSignOut, falPencilAlt, falCheck, falPen, falArrowToBottom,
  falEnvelope, falEnvelopeOpenText, falBallotCheck, falUserInjured, falMars, falVenus,
  farCalendarAlt, farDotCircle, farCheckSquare, farPrescriptionBottle, farSitemap, farTags,
  farSearch, farCertificate, farListAlt, farCalculatorAlt, farEdit, farHeading, farText, farMinus,
  farLevelDownAlt, farAngleDown, farTimes, farBars, fasQuestionCircle, fasPen, fasCheck,
  fasInfoCircle, fasLockAlt, fasFilter, fasBallotCheck, fasUserInjured, fasCircle, fasTrashAlt,
  fasPlus, fasAsterisk, fasArrowsAlt, fasSquare, fasLevelUpAlt, fasLevelDownAlt, fasListUl,
  falFilePdf, falFileExport, fasTimes, fasEye, fasEyeSlash, falTimes, falQuestionCircle, fasClock,
  fasUserTie, fasCheckCircle, fasEnvelope, falHandPaper, fasExclamationTriangle,
  fadExclamationTriangle, falPause, farExternalLink, fasClone, farEdit, fasRepeat, fasSortAlt,
  fasSearch, farSpellCheck, farChevronDown, farChevronRight, farChevronLeft, falCopy, falSpellCheck,
  fasGripVertical, farHistory, fasPencil, fasHistory, fasArrowAltSquareRight, fasUserShield,
  farAngleDoubleRight, farUserShield, falEllipsisH, falHome, fasAngleDown, falUsers, fasStar,
  falKey, falGlobe, farKey, falHistory, falMapMarkerAlt, falClone, farClone, farTrashAlt,
  farArrowToBottom, farEnvelope, farGlobe, fadGift, falMeh, falFileCsv, farCommentAltExclamation,
  fasCommentAltExclamation, falChartNetwork, falUser, falFileUpload, falCreditCard, falUserCrown,
  falSignOutAlt, falSquare, falLevelUpAlt, farSquare, falUserClock, falThList, falLink,
  fasFileCertificate, fasPlusCircle, farCheck, fadUserShield, fasBook, fasArchive, falArchive,
];
